import { useState } from 'react';
import SeccionEducativa from './Ventas/SeccionEducativa'
import Grados from './Ventas/Grados'
import Notificaciones from './Ventas/Notificaciones'
import ListaPaquetes from './Ventas/ListaPaquetes'


// https://splidejs.com/integration/react-splide/
function Vender({usuario, configuracion, bookDB, setVista, inventario, carritoBase, setCarritoBase}) {
    const [ Seccion,           setSeccion ] = useState(configuracion.grados)
    const [ Grado,             setGrado   ] = useState('')
    const [ Paquete,           setPaquete ] = useState('')
    const [ checkedState,      setCheckedState ] = useState(
        [{
            "data":"",
            "state":false

        }])
    const [ totalSeleccionado, setTotalSeleccionado ] = useState(0)
    const [ isChecked,         setIsChecked         ] = useState(false)
    let avisos = configuracion.avisos
    // Funcion para mostra la seccion educativa o los grados
    let mostarSeccionEducativa = () => {
        // https://stackoverflow.com/questions/48458334/functions-are-not-valid-as-a-react-child-this-may-happen-if-you-return-a-compon
        if (Grado == '') {
            return <SeccionEducativa Seccion = {Seccion} Grado = {Grado} setGrado = {setGrado} />
        }else{
            return <Grados Grado = {Grado} 
                           setGrado = {setGrado} 
                           setPaquete = {setPaquete} 
                           setTotalSeleccionado = {setTotalSeleccionado}
                           setCheckedState = {setCheckedState}
                           setIsChecked = {setIsChecked}
                           Colegio = {configuracion.nombre}
                    />

        } 
    }

    // Funcion para mostrar las notificaciones o la lista
    let mostrarAvisosLista = () => {
        if (Paquete == '') {
            return <Notificaciones avisos = {avisos} />
        }else{
            return <ListaPaquetes Paquete = {Paquete} 
                                  setTotalSeleccionado = {setTotalSeleccionado} 
                                  totalSeleccionado = {totalSeleccionado} 
                                  checkedState = {checkedState}
                                  setCheckedState = {setCheckedState}
                                  isChecked = {isChecked}
                                  setIsChecked = {setIsChecked}
                                  bookDB = {bookDB}
                                  usuario = {usuario}
                                  setVista = {setVista}
                                  carritoBase = {carritoBase}
                                  setCarritoBase = {setCarritoBase}
                                  inventario = {inventario}
                    />
        }
    }
    
 
    // Funcion encargada de renderizar las notificaciones 

    const notificaciones = () => {

            let elementos = (

                <div className="relative flex flex-col items-center w-11/12 my-5 border border-gray-400 border-solid rounded-md lg:shadow-xl lg:ml-6 lg:w-8/12 lg:border lg:border-solid lg:border-gray-400 lg:my-0 h-5/6">
                
                    { mostrarAvisosLista() }
                    
                </div>
            )

        return elementos

    }


    
    // FUNCION ENCARGADA DE MOSTRAR EL GRADO ESCOLAR

    const seccionEducativa = () => {

        let elementos = (

            <div className="relative flex flex-col items-center w-11/12 h-auto border border-gray-400 border-solid rounded-md lg:shadow-xl lg:h-5/6 lg:mr-6 lg:w-4/12">

                <div className="flex items-center justify-center w-full px-5 pt-5 lg:h-1/2">

                    <img src={configuracion.logo} alt="excuela-img" className="w-2/4 my-4 lg:w-4/12 2xl:w-4/12 2xl:my-0"/>

                </div>

                { mostarSeccionEducativa() }

            </div>
        )

        return elementos
    }

    return (

        <div className="flex flex-row justify-between w-full lg:h-contenedor">

            <section className="flex flex-col items-center w-full h-auto">

                <div className="box-border flex flex-col items-center w-full h-auto overflow-hidden lg:h-full lg:flex-row">

                    { seccionEducativa() }

                    { notificaciones() }

                </div>

            </section>

        </div>
    )
}

export default Vender