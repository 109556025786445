import { useEffect, useState } from "react"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
// import Carrito from '../Carrito'

const ListaPaquetes = ({Paquete, setTotalSeleccionado, totalSeleccionado, checkedState, setCheckedState, isChecked, setIsChecked, bookDB, usuario, setVista, inventario, carritoBase, setCarritoBase}) => {

    const [error,   seterror    ] = useState('')
    const [valido,  setValido   ] = useState(false)

    let total = 0

    let id_paquete

    //Funcion de manejo de all checked
    const handleInputChangeAll = () => {
        let total = 0
        
        checkedState.forEach(element => {
            if (isChecked) {
                if (element.data.obligatorio == 0) {
                    element.state = false
                } else {
                    total = total + element.data.precio
                }
            } else {
                element.state = true
                total = total + element.data.precio
            }
        })

        seterror('')
        setIsChecked(!isChecked)
        // setValido(!isChecked || total > 0)
        setTotalSeleccionado(total)
    }

    // Funcion de manejo de checkboxes por aparte
    const handleInputChange = (event, element, posicion) => {
        if (isChecked) {
            setIsChecked(false)
        }
        let libros = []
        let select_todos = true
        // let al_menos_uno = false
        setCheckedState(() => {
            checkedState.forEach((element, index) => {
                if (index === posicion) {
                    element.state = !element.state
                    libros.push(element)
                } else {
                    libros.push(element)
                }
                select_todos = select_todos && element.state
                // if (element.state) {
                //     al_menos_uno = element.state
                // }
            })
            setIsChecked(select_todos)
            // setValido(select_todos || al_menos_uno)
            return libros
        })
        if (event.target.checked) {
            seterror('')
            setTotalSeleccionado(() => totalSeleccionado + element.precio)
        } else {
            setTotalSeleccionado(() => totalSeleccionado - element.precio)
        }
    }

    useEffect(() => {
        let al_menos_uno = false
        checkedState.forEach(element => {
            if (element.state || element.data.obligatorio == 1) {
                al_menos_uno = true
            }
        })
        setValido(al_menos_uno)
    })


    //Funcion de insercion de los datos seleccionados
    const guardarLista = (e) => {

        e.preventDefault()

        let consulta = bookDB

        const MySwal = withReactContent(Swal)

        let listaLibros = {};

        listaLibros.id= id_paquete

        listaLibros.colegio = Paquete.colegio

        listaLibros.seccionEducativa = Paquete.seccionEducativa

        listaLibros.grado = Paquete.grado

        listaLibros.nombre = ""

        listaLibros.apellido_paterno = ""

        listaLibros.apellido_materno = ""

        if (!checkedState.isValid) {

            if (checkedState.filter(libro => libro.state == true).length > 0) {

                listaLibros.detalles = checkedState.filter(libro => libro.state == true)

                consulta.do(event => {

                    let db = event.target.result;
    
                    let insertTransaction =  db.transaction(['contenidocarrito'], 'readwrite');
    
                    let objectStore = insertTransaction.objectStore('contenidocarrito');
        
                    objectStore.add({
                        estatus: 'carrito',
                        info: listaLibros,
                        id_pedido: null
                    })
        
                    insertTransaction.oncomplete = function(evnt) {
                    
                        MySwal.fire({
                            title: <p>Lista agregada</p>,
                            text:'La lista de libros a sido agregada exitosamente al carrito',
                            icon:'success',
                            confirmButtonColor: '#2563EB',
                          }).then(function(isConfirm) {
                            if (isConfirm) {
                                let carrito = carritoBase
                                carrito.push([])
                                setVista('/carrito');
                                setCarritoBase(carrito)
                            }
                          })
    
                    }
        
                    insertTransaction.onerror = function(event) {
                        console.error(event.target.error.message, event.target);
                        seterror("Ocurrio un problema al insertar, favor de contactar al equipo de soporte")
                    };
    
                })
                
            }else{

                seterror ("Tienes que seleccionar al menos una opción .")

            }
            
        }else{

            seterror("Información erronea, favor de contactar al equipo de soporte")

        }
        
    }

    // Funcion para renderizar los checkboxs
    const getLibros = () => {

        let listaLibros = []

        let cantidad

        Paquete.forEach(element => {

            id_paquete = element.id

            element.libros.forEach((element, index) => {

                let inventarioLibro = inventario.filter(libro => libro.id == element.id)

                cantidad = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(element.precio);

                listaLibros.push(

                    <div key={element.id} className="grid w-full grid-cols-12 grid-rows-1 gap-4 my-4">

                        <div className="w-10 h-10 col-span-1">

                            <input 
                                    id={"ch"+index} 
                                    name={"ch"+index}  
                                    type="checkbox"  
                                    className="custom-check--bk" 
                                    onChange={ e => {handleInputChange(e, element, index)}  }
                                    checked={(element.obligatorio == 1 ? 1 : (isChecked == false ? checkedState[index].state : isChecked))}
                            /> 

                            <label 
                                    htmlFor={"ch"+index} 
                                    className={(element.obligatorio == 1 ? "opacity-50 pointer-events-none": "")+ " custom-check-label--bk"}
                            >

                                <span className="custom-check-span--bk"></span>

                            </label>

                        </div>

                        <div className="flex flex-col col-start-2 col-end-13 text-left lg:col-end-7">
                                
                            <span className="font-rob-light text-gray-bk">{element.nombre}</span>

                            <span className={(element.obligatorio == 0 ? "hidden" : "") +" font-rob-light text-sm text-red-notification uppercase"}>OBLIGATORIO</span>

                            <span className ="text-sm uppercase font-rob-light text-green-book">{inventarioLibro[0].existencias} disponibles</span>

                         </div>

                        <div className="flex pl-8 text-sm text-left uppercase lg:col-start-8 lg:col-end-11 lg:text-center col-span-full lg:pl-0 lg:flex-none">

                            <span className="block mr-3 uppercase lg:hidden text-gray-bk font-rob-bold">ISBN</span>
                                
                            <span className="font-rob-light text-gray-bk">{element.isbn}</span>

                        </div>

                        <div className="inline-flex col-start-1 pl-8 text-sm text-left uppercase lg:col-start-11 lg:col-end-13 col-span-full lg:pl-0">

                            <span className="block mr-3 uppercase lg:hidden text-gray-bk font-rob-bold">PRECIO</span>

                            <span className="font-rob-bold text-green-book">{cantidad} MXN</span>

                        </div>

                    </div>
                )
                    
            });
                
        });

        return listaLibros
            
    }

    total = new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(totalSeleccionado);

        //Funcion para renderizar tablas 
        const tableBooks = () => {

            //Formateo del total 
                let table = (

                    <form onSubmit = { guardarLista } className="w-full h-full p-2 overflow-hidden lg:p-10">
                    
                        <div className ="hidden w-full grid-cols-12 grid-rows-1 gap-4 my-4 lg:grid">

                            <div className="col-span-7 text-left">
                                <span className="uppercase text-gray-bk font-rob-bold">
                                    {Paquete.grado}
                                </span>
                            </div>

                            <div className="col-start-8 text-left">
                                <span className="uppercase text-gray-bk font-rob-bold">
                                    ISBN
                                </span>
                            </div>

                            <div className="col-start-11 col-end-12 text-left">
                                <span className="uppercase text-gray-bk font-rob-bold">
                                    PRECIO
                                </span>
                            </div>

                        </div>

                        <div className="grid items-center w-full h-10 grid-cols-12 pl-2 mt-2 mb-2 border-b border-gray-300">
                            
                            <div className="relative flex items-center justify-start w-10 h-10 col-span-1">
                                    
                                <input
                                    id="all"
                                    name="allCheck"
                                    type="checkbox"
                                    className="custom-check--bk"
                                    checked={isChecked}
                                    onChange={handleInputChangeAll}
                                />

                                <label htmlFor="all" className="custom-check-label--bk">

                                    <span className="custom-check-span--bk"></span>

                                </label>

                            </div>
                            
                            <div className="col-span-10 col-start-2 text-left lg:col-span-7">

                                <span className="text-sm uppercase lg:text-base font-rob text-gray-bk"> seleccionar todos los títulos </span>

                            </div>
                            
                        </div>
                        
                            <div className="h-auto p-2 overflow-y-auto lg:h-1/2 scrollBar-BK">

                                {
                                    getLibros() 
                                }
                            
                            </div>
                            

                            <div className="flex items-center justify-end w-full h-10 grid-cols-12 pl-2 mt-2 border-t border-gray-300 lg:grid">
                                <div className="flex text-sm text-left uppercase lg:col-start-8 lg:col-end-11 lg:text-center lg:pl-0 pl-9 lg:flex-none"> 
                                    <span className="font-rob-bold text-gray-bk">SUBTOTAL</span>
                                </div>
                                <div className="ml-2 text-sm text-left uppercase lg:col-start-11 lg:col-end-13"> 
                                    <span className="font-rob-bold text-green-book">{total} MXN</span>
                                </div>
                            </div>

                            <div className="flex justify-center w-full">
                                
                            </div>

                            <div className="flex flex-col items-center justify-center w-full mt-10">
                                <span className="mb-3 text-red-notification font-rob-light">{error}</span>
                                <button type="submit" className={(valido? "bg-green-button cursor-pointer" : "bg-gray-bk cursor-default") + " relative flex items-center justify-center h-10 px-3 text-center text-white uppercase break-all border border-gray-400 border-solid rounded-md appearance-none font-rob-bold lg:w-5/12 lg:text-lg md:text-sm sm:text-sm lg:px-0"}>Aregar al carrito</button>
                            </div>

                    </form>
                )

            return table
        }

    return (tableBooks())

}


export default ListaPaquetes
