import { useState, useEffect } from "react"
import img_documento from "../../assets/img/documento.svg"

export default ({bookDB, ventas, reportes}) => {
    const [ reporte,    setReporte  ] = useState('no_id')
    const [ cupones,    setCupones  ] = useState([])

    // const getMes = numero => {
    //     let texto = 'Error'
    //     switch (numero) {
    //         case 0:     texto = 'enero';        break;
    //         case 1:     texto = 'febrero';      break;
    //         case 2:     texto = 'marzo';        break;
    //         case 3:     texto = 'abril';        break;
    //         case 4:     texto = 'mayo';         break;
    //         case 5:     texto = 'junio';        break;
    //         case 6:     texto = 'julio';        break;
    //         case 7:     texto = 'agosto';       break;
    //         case 8:     texto = 'septiembre';   break;
    //         case 9:     texto = 'octubre';      break;
    //         case 10:    texto = 'noviembre';    break;
    //         case 11:    texto = 'diciembre';    break;
    //     }
    //     return texto
    // }

    const getMesNumero = numero => {
        return (numero < 9? '0' : '') + (numero + 1)
    }

    const getFormato = (valor) => {
        return Intl.NumberFormat('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(valor)
    }

    function sha512(str) {
        return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
            return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('')
        })
    }

    const getDescuento = (cupon) => {
        let descuento = 0
        if (cupon.length > 0) {
            descuento = sha512(cupon).then(codigo => {
                return codigo
            })
        }
        return descuento
    }

    useEffect(() => {
        let consulta = bookDB
        consulta.do(evnt => {
            let db          = evnt.target.result;
            let objStore    = db.transaction(["cupones"], "readwrite").objectStore("cupones");
            let request     = objStore.getAll();
            request.onerror = (event) => {
                console.error("Error de borrado", event.target)
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    setCupones[evnt.target.result]
                }
            }
        })
    })

    const Seleccion = () => {
        return(
            <div className="w-full p-4 border-2 border-gray-300 rounded-md print:p-0 print:border-0 print:border-transparent lg:h-contenedor min-h-contenedor">
                <section className="flex flex-col justify-between w-full h-auto p-4 lg:h-full lg:flex-row">
                    <div className="w-full p-4 lg:w-1/2">
                        <div className="w-full h-16">
                            <h2 className="text-2xl text-green-book font-rob-bold">REPORTES DE CIERRE DE CAJA</h2>
                        </div>
                        <div className="flex items-center justify-center w-full py-10 h-60 lg:h-scroll">
                            <img className="max-w-full max-h-full w-52" src={img_documento} alt="Reportes" />
                        </div>
                    </div>

                    <div className="w-px h-full bg-gray-bk"></div>

                    <div className="flex items-center w-full p-4 lg:h-full lg:w-1/2">
                        <div className="relative w-full">
                            <select defaultValue={reporte} onChange={evnt => setReporte(evnt.target.value)} className="block w-full px-4 py-0 pr-8 text-gray-700 border rounded appearance-none bg-grey-lighter border-grey-lighter h-11">
                                <option value="no_id" disabled>Seleccione un reporte</option>
                                {reportes.sort((a, b) => {
                                    return b.codigo - a.codigo
                                }).map(reporte => {
                                    let formato = 'Registro actual'
                                    if (reporte.status == 'close') {
                                        let fecha = new Date()
                                        fecha.setTime(reporte.codigo)
                                        // formato = fecha.getUTCDate() + '/' + getMes(fecha.getMonth()) + '/' + fecha.getFullYear() + ' - (' + reporte.colegio + ')'
                                        formato = fecha.getUTCDate() + '/' + getMesNumero(fecha.getMonth()) + '/' + fecha.getFullYear()
                                    }
                                    return <option key={reporte.id} value={reporte.id}>{reporte.nombre_colegio + ' ' + formato}</option>
                                })}
                            </select>
                            <div className="absolute right-0 flex items-center px-2 text-gray-700 pointer-events-none top-4">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="w-4 h-4">
                                    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                                </svg>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }

    const Reporte = ({reporte_id}) => {
        const reporte = reportes.filter(obj => obj.id == reporte_id)[0]
        // console.log(reporte.status)
        let fecha = new Date()
        fecha.setTime(reporte.codigo)
        const formato = fecha.getUTCDate() + '/' + getMesNumero(fecha.getMonth()) + '/' + fecha.getFullYear()
        let pago_efectivo = 0
        let pago_tarjeta = 0
        let pago_transferencia = 0
        let total_libros = 0
        // const ventas_reporte = 
        ventas.filter(venta => {
            if (venta.id_reporte == reporte_id) {
                // console.log(venta.info.cupon_descuento)
                // console.log(getDescuento(venta.info.cupon_descuento))
                // var descuento = 0;
                // getDescuento(venta.info.cupon_descuento).then(resp => {
                //     descuento = resp
                // })

                // console.log(venta.info.cupon_descuento, descuento)
                venta.info.contenido[0].libros.map(libro => {
                    if (venta.info.metodo_pago_id == "6") {
                        pago_efectivo += parseInt(libro.precio)
                    }
                    if (venta.info.metodo_pago_id == "7") {
                        pago_tarjeta += parseInt(libro.precio)
                    }
                    if (venta.info.metodo_pago_id == "8") {
                        pago_transferencia += parseInt(libro.precio)
                    }
                    total_libros++
                })
                return true
            }
        })
        const total_efectivo = (
            parseInt(reporte.efectivo.billete_1000) * 1000 +
            parseInt(reporte.efectivo.billete_500) * 500 +
            parseInt(reporte.efectivo.billete_200) * 200 +
            parseInt(reporte.efectivo.billete_100) * 100 +
            parseInt(reporte.efectivo.billete_50) * 50 +
            parseInt(reporte.efectivo.billete_20) * 20 +
            parseInt(reporte.efectivo.monto_monedas)
        )

        return(
            <div className="flex flex-col justify-between w-full p-4 border-2 border-gray-300 rounded-md print:p-0 print:border-0 print:border-transparent min-h-contenedor">
                <section className="flex flex-col items-center w-full h-auto p-4">
                    <div className="flex flex-col w-full max-w-3xl py-8">
                        <div className="w-full h-16">
                            <span className="text-xl font-bold text-red-book">REPORTE {reporte.nombre_colegio + ' ' + formato}</span>
                        </div>
                        <div className="w-full">
                            <div className="flex items-center justify-between w-full px-2 text-lg h-14 print:h-10 text-gray-bk font-rob-light row-cebra-reporte">
                                <span>Pago efectivo</span>
                                <span>${getFormato(pago_efectivo)}</span>
                            </div>
                            <div className="flex items-center justify-between w-full px-2 text-lg h-14 print:h-10 text-gray-bk font-rob-light row-cebra-reporte">
                                <span>Total efectivo</span>
                                <span>${getFormato(total_efectivo)}</span>
                            </div>
                            <div className="flex items-center justify-between w-full px-2 text-lg h-14 print:h-10 text-gray-bk font-rob-light row-cebra-reporte">
                                <span>Pago con tarjeta</span>
                                <span>${getFormato(pago_tarjeta)}</span>
                            </div>
                            <div className="flex items-center justify-between w-full px-2 text-lg h-14 print:h-10 text-gray-bk font-rob-light row-cebra-reporte">
                                <span>Pago con transferencia</span>
                                <span>${getFormato(pago_transferencia)}</span>
                            </div>
                            <div className="flex items-center justify-between w-full px-2 text-lg h-14 print:h-10 text-gray-bk font-rob-bold row-cebra-reporte">
                                <span>Total</span>
                                <span>${getFormato(parseInt(total_efectivo) + parseInt(pago_tarjeta) + parseInt(pago_transferencia))}</span>
                            </div>
                        </div>
                        <div className="w-full py-8 print:py-4">
                            <div className="flex items-center justify-between w-full px-2 text-lg h-14 print:h-10 text-gray-bk font-rob-bold bg-gray-row-reporte">
                                <span>Total de libros</span>
                                <span>{total_libros}</span>
                            </div>
                        </div>
                    </div>
                    <div className={(reporte.status == "close"? "flex" : "hidden") + " flex-col w-full max-w-3xl py-8 print:py-4"}>
                        <table className="w-full text-lg text-gray-bk">
                            <thead>
                                <tr className="h-14 print:h-10">
                                    <td className="px-2 font-bold">Desglose efectivo:</td>
                                    <td className="px-2 font-bold text-right">Cant:</td>
                                    <td className="px-2 font-bold text-right"></td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="h-14 print:h-10 row-cebra-reporte font-rob-light">
                                    <td className="px-2">$ 1000.00</td>
                                    <td className="px-2 text-right">{reporte.efectivo.billete_1000}</td>
                                    <td className="px-2 text-right">${getFormato(1000 * reporte.efectivo.billete_1000)}</td>
                                </tr>
                                <tr className="h-14 print:h-10 row-cebra-reporte font-rob-light">
                                    <td className="px-2">$ 500.00</td>
                                    <td className="px-2 text-right">{reporte.efectivo.billete_500}</td>
                                    <td className="px-2 text-right">${getFormato(500 * reporte.efectivo.billete_500)}</td>
                                </tr>
                                <tr className="h-14 print:h-10 row-cebra-reporte font-rob-light">
                                    <td className="px-2">$ 200.00</td>
                                    <td className="px-2 text-right">{reporte.efectivo.billete_200}</td>
                                    <td className="px-2 text-right">${getFormato(200 * reporte.efectivo.billete_200)}</td>
                                </tr>
                                <tr className="h-14 print:h-10 row-cebra-reporte font-rob-light">
                                    <td className="px-2">$ 100.00</td>
                                    <td className="px-2 text-right">{reporte.efectivo.billete_100}</td>
                                    <td className="px-2 text-right">${getFormato(100 * reporte.efectivo.billete_100)}</td>
                                </tr>
                                <tr className="h-14 print:h-10 row-cebra-reporte font-rob-light">
                                    <td className="px-2">$ 50.00</td>
                                    <td className="px-2 text-right">{reporte.efectivo.billete_50}</td>
                                    <td className="px-2 text-right">${getFormato(50 * reporte.efectivo.billete_50)}</td>
                                </tr>
                                <tr className="h-14 print:h-10 row-cebra-reporte font-rob-light">
                                    <td className="px-2">$ 20.00</td>
                                    <td className="px-2 text-right">{reporte.efectivo.billete_20}</td>
                                    <td className="px-2 text-right">${getFormato(20 * reporte.efectivo.billete_20)}</td>
                                </tr>
                                <tr className="h-14 print:h-10 row-cebra-reporte font-rob-light">
                                    <td className="px-2">Monedas</td>
                                    <td className="px-2 text-right">{reporte.efectivo.conteo_monedas}</td>
                                    <td className="px-2 text-right">${getFormato(reporte.efectivo.monto_monedas)}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="w-full py-8">
                            <div className="flex items-center justify-between w-full px-2 text-lg h-14 print:h-10 text-gray-bk font-rob-bold bg-gray-row-reporte">
                                <span>Total desglosado</span>
                                <span>${getFormato(total_efectivo)}</span>
                            </div>
                        </div>
                    </div>
                </section>
                <div className="flex flex-col items-center justify-around w-full h-28 print:hidden">
                    <button onClick={() => window.print()} className="relative flex items-center justify-center w-48 uppercase border rounded-md cursor-pointer border-green-button hover:bg-green-button hover:text-white h-11 font-rob-bold text-green-button">IMPRIMIR</button>
                    <button onClick={() => setReporte('no_id')} className="relative flex items-center justify-center w-48 uppercase border border-gray-400 rounded-md cursor-pointer hover:bg-gray-bk hover:text-white h-11 font-rob-bold text-gray-bk">REGRESAR</button>
                </div>
            </div>
        )
    }

    return (reporte == "no_id"? <Seleccion /> : <Reporte reporte_id={reporte} />)
}
