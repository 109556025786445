import { useEffect, useState } from "react"
import Loading from './Loading'

export default ({bookDB, colegio}) => {
    const [ ventas,     setVentas       ] = useState([])
    const [ carga,      setCarga        ] = useState(false)
    const [ id_pedido,  setId_pedido    ] = useState(undefined)
    const [ venta,      setVenta        ] = useState({
        id: '',
        colegio: '',
        info: {
            metodo_pago_id: "",
            nombre_contacto: "",
            apellidos_contacto: "",
            correo_contacto: "",
            colegio: "",
            referencia: "",
            cupon_descuento: "",
            fecha_pago: "",
            telefono_contacto: "",
            contenido: []
        },
        sync: ''
    })

    let ventasSave  = []

    const cargarVentas = () => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction("ventas", "readonly").objectStore("ventas").index("colegio")
            let request     = objStore.getAll()
    
            request.onerror = async (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    ventasSave = evnt.target.result
                    ventasSave.sort((a, b) => {
                        return (b.folio == undefined || b.folio - a.folio)
                    })
                    setVentas(ventasSave)
                } else {
                    console.log('Sin ventas:1');
                }
                setCarga(true)
            }
        })
    }

    /*const marcarSync = (data_registro) => {
        let id_registro = data_registro.id_registro
        let consulta = bookDB;
        consulta.do(evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction(["ventas"], "readwrite").objectStore("ventas")
            let request     = objStore.get(id_registro)

            request.onerror = (event) => {
                console.error("Error de borrado", event.target)
            }

            request.onsuccess = (evnt) => {
                let venta = evnt.target.result
                venta.sync = "true"
                venta.serie = data_registro.serie
                venta.folio = data_registro.folio
                let resp = objStore.put(venta)

                resp.onerror = (evt) => {
                    console.error("Error de apertura", evt.target)
                }
                resp.onsuccess = () => {
                    console.log('Venta ' + venta.serie + venta.folio + ' registrada')
                }
            }
        })
    }

    const syncVentas = () => {
        let consulta = bookDB
        consulta.do(evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction("ventas", "readonly").objectStore("ventas").index("colegio")
            let request     = objStore.getAll()
    
            request.onerror = async (event) => {
                console.error("Error de apertura", event.target);
                cargarVentas()
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result && window.navigator.onLine) {
                    let ventasPrev = evnt.target.result.map(venta => {
                        let resp = venta.info
                        resp.contenido = resp.contenido.map(paq => {
                            let resp_paq = paq
                            resp_paq.libros = resp_paq.libros.map(libro => libro.id)
                            return resp_paq
                        })
                        resp.id = venta.id
                        resp.sync = venta.sync == "true"
                        return resp
                    }).filter(venta => {
                        return !venta.sync
                    })
                    if (ventasPrev.length > 0) {
                        let ventas_sync = ventasPrev
            
                        Promise.all(ventas_sync.map(venta => fetch((urlHome + '/api/venta_movil/pagar'), {
                            method: 'POST',
                            body: JSON.stringify(venta),
                            headers: {
                                'Authorization': 'Bearer ' + token,
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }))).then(responses => {
                            return Promise.all(responses.map(response => response.json()));
                        }).then(data => {
                            data.map(resp => {
                                if (resp.success) {
                                    marcarSync(resp.data)
                                } else {
                                    console.log("Error:", resp.message)
                                }
                            })
                            cargarVentas()
                        }).catch((error) => {
                            console.log('Hubo un problema con la petición:\n', error)
                            cargarVentas()
                        });
                    } else {
                        console.log('No hay nada para sincronizar')
                        cargarVentas()
                    }
                } else {
                    cargarVentas()
                }
            }
        })
    }*/

    const verDetalles = (venta) => {
        let consulta = bookDB
        consulta.do(async evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction("ventas", "readonly").objectStore("ventas")
            let request     = objStore.get(venta)
            
            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    setVenta(evnt.target.result)
                    setId_pedido(venta)
                    // console.log(evnt.target.result);
                } else {
                    console.log('Sin ventas:1');
                }
                setCarga(true)
            }
        })
    }

    const getFormato = (valor) => {
        return Intl.NumberFormat('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(valor);
    }

    useEffect(cargarVentas, [])

    const Ventas = () => {
        if (carga) {
            if (ventas.length > 0) {
                return ventas.map(venta => {
                    let valor = 0
                    venta.info.contenido.map(paquete => {
                        paquete.libros.map(libro => {
                            valor += libro.precio
                        })
                    })
                    // console.log(venta.info);
                    return (
                        <div key={venta.id} className="items-center justify-between inline-block w-full h-auto max-w-full py-4 text-base text-center text-gray-500 border-b border-gray-300 lg:text-left lg:py-0 lg:w-full xl:h-32 xl:flex xl:flex-row md:text-center rob-light">
                            <div className="inline-flex flex-col justify-between h-auto p-4 text-center lg:text-left xl:h-16 sm:p-4 xl:py-0 lg:w-1/4">
                                <span className="font-medium font-rob-bold text-grayDarkBT">VENTA</span>
                                <span className={venta.sync == 'false'? "text-red-book font-rob-bold" : "font-light"} >{venta.sync == "true"? venta.serie + venta.folio : 'Sin sincronizar'}</span>
                            </div>
                            <div className="inline-flex flex-col justify-between h-auto p-4 text-center lg:text-left xl:h-16 sm:pl-4 xl:py-0 sm:pr-4 lg:w-1/4">
                                <span className="font-medium font-rob-bold text-grayDarkBT">TOTAL</span>
                                <span className="font-light">${getFormato(valor - venta.info.valor_descuento)}</span>
                            </div>
                            <div className="inline-flex flex-col justify-between w-auto h-auto p-4 text-center lg:text-left xl:h-16 sm:p-4 xl:py-0 md:w-1/4 lg:w-1/4">
                                <span className="font-medium font-rob-bold text-grayDarkBT">Fecha de pago</span>
                                {/* <span className="font-light">{venta.info.fecha_pago.replaceAll('-', ' / ')}</span> */}
                                <span className="font-light">{venta.info.fecha_pago}</span>
                            </div>
                            <br className="lg:hidden" />
                            <div className="inline-flex flex-col justify-between w-auto p-4 py-0 text-center lg:text-left">
                                <a onClick={() => verDetalles(venta.id)} className="cursor-pointer font-rob-bold text-blue-button">VER MÁS DETALLES</a>
                            </div>
                        </div>
                    )
                })
            } else {
                return (
                    <div className="relative flex items-center justify-center w-full h-auto overflow-x-hidden text-4xl font-thin uppercase min-h-contenedor lg:overflow-y-auto center lg:min-h-scroll lg:h-scroll text-gray-bk">
                        <span>Sin ventas.</span>
                    </div>
                )
            }
        } else {
            return (
                <div className="relative flex items-center justify-center w-full h-auto overflow-x-hidden uppercase min-h-contenedor lg:overflow-y-auto center lg:min-h-scroll lg:h-scroll text-gray-bk">
                    <Loading />
                </div>
            )
        }
    }

    const Pedido = () => {
        let total = 0
        let key_pedido = 0
        let metodo_pago = colegio.metodos_pago.filter(metodo => {
            return metodo.id == venta.info.metodo_pago_id
        })[0].descripcion

        return (
            <div className="w-full p-4 border-2 border-gray-300 rounded-md print:p-0 print:border-0 print:border-transparent min-h-contenedor">
                <div className="flex flex-col w-full py-4 border-b-2 print:pb-1 print:pt-0 print:border-b">
                    <div className="flex items-center justify-between w-full pb-2 text-xl text-left print:pb-1 font-rob-bold lg:w-auto">
                        <span className={(venta.sync == "true"? "text-blue-button" : "text-red-book" + " print:text-sm print:pb-2")}>{venta.sync == "true"? venta.serie + venta.folio : "Sin sincronizar"}</span>
                        <button onClick={() => window.print()} className="text-base uppercase border rounded-md cursor-pointer print:hidden font-rob-medium w-28 h-9 border-green-button text-green-button hover:bg-green-button hover:text-white">Imprimir</button>
                    </div>
                    <div className="flex flex-col lg:flex-row print:justify-between print:flex-row">
                        <div className="w-full text-left print:w-3/10 lg:w-auto lg:pr-16 print:flex print:flex-col print:justify-start">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">Fecha de venta: </span>
                            <span className="text-base print:text-xs text-gray-bk">{venta.info.fecha_pago.replaceAll('-', ' / ')}</span>
                        </div>
                        <div className="w-full text-left print:w-3/10 lg:w-auto print:flex lg:pr-16 print:flex-col print:justify-start">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">Método de pago: </span>
                            <span className="text-base print:text-xs text-gray-bk">{metodo_pago}</span>
                        </div>
                        <div className="w-full text-left print:w-4/10 lg:w-auto print:flex print:flex-col print:justify-start">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">Referencia: </span>
                            <span className="text-base print:text-xs text-gray-bk">{venta.info.referencia}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full py-4 border-b-2 print:py-1 print:border-b">
                    <div className="w-full py-1 text-left lg:w-auto">
                        <p className="text-base text-left uppercase print:text-xs text-green-button font-rob-bold">Datos generales</p>
                    </div>
                    <div className="flex flex-col pt-1 lg:flex-row print:justify-between print:flex-row">
                        <div className="w-full text-left print:w-3/10 lg:w-auto lg:pr-16 print:flex print:flex-col print:justify-start">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">Nombre: </span>
                            <span className="text-base print:text-xs text-gray-bk">{venta.info.nombre_contacto}</span>
                        </div>
                        <div className="w-full text-left print:w-3/10 lg:w-auto print:flex lg:pr-16 print:flex-col print:justify-start">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">Apellido(s): </span>
                            <span className="text-base print:text-xs text-gray-bk">{venta.info.apellidos_contacto}</span>
                        </div>
                        <div className="w-full text-left print:w-4/10 lg:w-auto print:flex print:flex-col print:justify-start">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">Celular: </span>
                            <span className="text-base print:text-xs text-gray-bk">{venta.info.telefono_contacto}</span>
                        </div>
                    </div>
                    <div className="flex flex-col pb-1 lg:flex-row print:justify-between print:flex-row print:pt-2 lg:pt-2">
                        <div className="w-full text-left lg:w-auto lg:pr-16 print:w-auto print:flex print:flex-col print:justify-start">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">Correo para recepción de licencias digitales: </span>
                            <span className="text-base print:text-xs text-gray-bk">{venta.info.email_licencias}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full py-4 border-b-2 print:py-1 print:border-b">
                    <div className="w-full py-1 text-left font-rob-bold lg:w-auto">
                        <span className="uppercase print:text-xs text-blue-button">ENTREGA</span>
                    </div>
                    <div className="flex flex-wrap">
                        <div className="w-full py-1 text-left lg:w-auto">
                            <span className="text-base uppercase print:text-xs text-gray-bk font-rob-bold">COLEGIO: </span>
                            <span className="text-base print:text-xs text-gray-bk">{venta.info.colegio}</span>
                        </div>
                    </div>
                </div>
                <div className="flex flex-col w-full py-4 print:py-1">
                    <div className="w-full py-1 text-left font-rob-bold lg:w-auto">
                        <span className="uppercase print:text-xs text-blue-button">Datos de facturación del último pedido</span>
                    </div>
                    <div className="flex flex-col w-full border-b-2 border-gray-400 print:border-b lg:border-gray-200 print:border-gray-200">
                        {venta.info.contenido.map(paquete => {
                            return (
                                <div key={paquete.paquete_id + '-' + key_pedido} className="flex flex-col w-full lg:flex-row print:flex-row">
                                    <div className="w-full py-5 border-t-2 border-gray-400 print:py-0 print:border-t lg:border-gray-200 print:border-gray-200 lg:border-r-2 print:border-r-2 lg:w-1/3 print:w-3/10">
                                        <div className="flex w-full py-1">
                                            <div className="flex flex-col pr-6">
                                                <span className="py-2 text-base uppercase print:py-1 print:text-xs text-gray-bk font-rob-bold">Colegio: </span>
                                                <span className="py-2 text-base uppercase print:py-1 print:text-xs text-gray-bk font-rob-bold">Grado: </span>
                                            </div>
                                            <div className="flex flex-col">
                                                <span className="py-2 text-base print:py-1 print:text-xs text-gray-bk">{venta.info.colegio}</span>
                                                <span className="py-2 text-base print:py-1 print:text-xs text-gray-bk">{paquete.grado}</span>
                                            </div>
                                        </div>
                                        <div className="flex flex-col w-full py-1">
                                            <div className="w-full py-1 text-left print:py-0 font-rob-bold lg:w-auto print:w-auto">
                                                <span className="uppercase print:text-xs text-blue-button">NOMBRE DEL ALUMNO:*</span>
                                            </div>
                                            <div className="flex flex-wrap">
                                                <div className="w-full py-1 text-left break-words print:py-0 lg:w-auto print:w-auto">
                                                    <span className="text-base print:text-xs text-gray-bk">{paquete.nombre_alumno + " " + paquete.apellido_paterno_alumno + " " + paquete.apellido_materno_alumno}</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="flex flex-col w-full py-2 lg:py-5 print:py-1 lg:border-t-2 print:border-t lg:w-2/3 print:w-7/10">
                                        {paquete.libros.map(libro => {
                                            key_pedido++
                                            total += libro.precio
                                            return (
                                                <div key={paquete.paquete_id + '-' + libro.id + '-' + key_pedido} className="flex flex-col justify-start w-full px-3 py-2 text-base text-left rounded-md lg:px-0 print:px-0 print:py-1 lg:justify-end print:justify-end row-cebra print:bg-transparent lg:bg-white lg:flex-row print:flex-row">
                                                    <div className="block w-auto pb-4 pl-0 pr-4 whitespace-pre-wrap print:text-xs lg:pb-0 print:pb-0 lg:w-texto-libro print:w-texto-libro-print lg:text-right print:text-right font-rob-medium lg:pl-4 print:pl-4 text-gray-bk">
                                                        <span>{libro.nombre}</span>
                                                    </div>
                                                    <div className="flex print:text-xs lg:w-28 print:w-20 text-gray-bk">
                                                        <span className="pr-4 lg:hidden print:hidden">ISBN</span>
                                                        <span>{libro.isbn}</span>
                                                    </div>
                                                    <div className="flex justify-between w-full lg:justify-end print:justify-end lg:text-right print:text-right lg:w-36 print:w-28 text-green-button">
                                                        <span className="lg:hidden print:hidden print:text-xs text-gray-bk">PRECIO</span>
                                                        <span className="inline-block whitespace-nowrap print:text-xs">${getFormato(libro.precio)} MXN</span>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="flex justify-end w-full py-1 print:py-1">
                    <span className="px-4 text-base uppercase print:text-xs text-gray-bk font-rob-bold">SUBTOTAL</span>
                    <span className="w-64 text-right print:text-xs text-green-button">${getFormato(total)} MXN</span>
                </div>
                <div className="flex justify-end w-full py-1 print:py-1">
                    <span className="px-4 text-base uppercase print:text-xs text-gray-bk font-rob-bold">DESCUENTO</span>
                    <span className="w-64 text-right print:text-xs text-green-button">${getFormato(venta.info.valor_descuento)} MXN</span>
                </div>
                <div className="flex justify-end w-full py-1 print:py-1">
                    <span className="px-4 text-base uppercase print:text-xs text-gray-bk font-rob-bold">Total</span>
                    <span className="w-64 text-right text-gray-700 print:text-xs font-rob-bold">${getFormato(total - venta.info.valor_descuento)} MXN</span>
                </div>
                <div className="flex justify-center w-full my-12 print:hidden">
                    <a onClick={() => setId_pedido(undefined)} className="appearance-none cursor-pointer relative block text-center lg:py-1 print:py-1 py-3 px-3 lg:w-2/12 print:w-2/12 w-full lg:my-1.5 print:my-1.5 rounded-md text-gray-bk border border-solid border-gray-400 hover:bg-white hover:border-blue-button hover:text-blue-button lg:text-lg print:text-lg md:text-base sm:text-sm">REGRESAR</a>
                </div>
            </div>
        )
    }

    const Lista = () => {
        return (
            <div className="w-full p-4 border-2 border-gray-300 rounded-md min-h-contenedor lg:min-h-contenedor">
                <div className="flex items-center justify-between w-full py-8 text-left border-b border-gray-300">
                    <span className="text-base font-rob-bold text-blue-button">VENDIDOS</span>
                </div>
                <Ventas />
            </div>
        )
    }

    return id_pedido != undefined? <Pedido /> : <Lista />
}
