import React, { Fragment } from 'react'


const Grados = ({Grado, setGrado, setPaquete, setCheckedState, setTotalSeleccionado, setIsChecked, Colegio}) => {

    let elementsRm = document.getElementsByClassName('contenedor-flecha')

    //Metodo para obtener el tamaño para conformar el json y el precio obligatorio
    const librosLista = (paquete, grado) => {
        paquete.grado = grado
        paquete.colegio = Colegio
        paquete.seccionEducativa = Grado.seccionEducativa
        let newArray = []
        let obligatorioCheck = 0
        paquete.forEach(element => {
            element.libros.forEach( (element) => {
                if (element.obligatorio) {
                    newArray.push({
                        "state":true,
                        "data":element
                    })
                    obligatorioCheck = obligatorioCheck + element.precio
                } else {
                    newArray.push({
                        "state":false,
                        "data":element
                    })
                }
            })
        })
        setCheckedState(newArray)
        setTotalSeleccionado(obligatorioCheck)
        setIsChecked(false)
        setPaquete(paquete)
    }

    const regresar = () => {
        setGrado('') 
        setPaquete('')
    }

    const handToggle = (comp) => {
        [].forEach.call(elementsRm, function(el) {
            el.classList.remove("activo");
        });
        document.getElementById('Grado'+comp).classList.add("activo");
    }
    // console.log(34, Grado.seccionEducativa);
    return (
        <div className="relative w-full h-full lg:w-11/12 lg:h-3/6">

            <div className="relative flex flex-col items-start h-full px-4 rounded-md lg:h-5/6 color-gray--bk lg:border lg:border-solid lg:border-gray-400">

               <div className="items-center justify-start hidden w-full py-2 border-0 border-gray-300 lg:flex lg:border-b">

                   <h4 className="text-base text-gray-500 uppercase font-rob-light">{ Grado.seccionEducativa }</h4>

               </div>

               <div className="flex flex-col w-full h-auto py-2 overflow-x-hidden text-left uppercase lg:h-full lg:overflow-y-auto recuadro-secciones lg:scrollBar-BK">

                    {
                        Grado.map((element , index) => {

                            return(

                                <div id={"Grado"+index} key={index} onClick={() => { handToggle(index); librosLista(element.paquetes, element.nombre); }} className= "relative flex items-center justify-center w-full h-10 py-8 my-3 border border-gray-400 rounded-lg cursor-pointer lg:py-2 lg:justify-start text-gray-bk hover:text-blue-book contenedor-flecha lg:border-0 lg:rounded-sm lg:my-0">

                                    <span className="w-auto transition-all lg:font-rob-light font-rob-bold overflow-ellipsis lg:mr-5 lg:w-1/3">{element.nombre}</span>
            
                                    <div className="w-2/3 pr-4 lg:flex hidden justify-start relative h-2.5">
            
                                        <div className="relative invisible overflow-hidden flecha-derecha lg:visible"></div>
            
                                    </div>
                                    
                                </div>

                            )

                        })
                    }
  
               </div>


               <div className="flex justify-end w-full py-4 border-0 border-gray-300 lg:border-t ">

                    <div className="flex items-center text-gray-400 cursor-pointer contensetpaqueteedor-flecha--izquierda hover:text-blue-book" onClick={() => {regresar()}}>

                        <div className="inline-block mr-3 flecha-izquierda"></div>

                        <span className="text-xs font-rob-light">REGRESAR</span>

                    </div>
                    
               </div>

            </div>

        </div>
    )
}

export default Grados
