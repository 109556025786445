function Footer() {
    return (
        <footer className="relative w-full bg-white footer sm:px-4 xl:px-16 print:hidden">
            <div className="flex justify-center w-full border-t-2 border-gray-300">
                <div className="flex flex-col items-center w-full py-2 md:flex-row justify-evenly">
                    <p className="text-xs leading-7 text-center md:leading-4 text-gray-bk font-rob-light">
                        <a href="https://booktrain.com.mx/aviso-de-privacidad" target="_blank">Aviso de privacidad</a> / <a href="https://booktrain.com.mx/politicas" target="_blank">Políticas de compra, entrega y devolución</a>
                    </p>
                    <p className="px-3 text-xs leading-7 md:leading-4 text-gray-bk font-rob-light">
                        © 2021 MYTANG S.A de C.V
                    </p>
                    <p className="px-3 text-xs leading-7 md:leading-4 text-gray-bk font-rob-light">
                        Desarrollado por <a href="https://inkwonders.com/" target="_blank"><span className="font-bold text-gray-bk">INK WONDERS</span></a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer