class Insertar {
    constructor(databasename, version, structure) {
        this.databasename   = databasename;
        this.version        = version;
        this.structure      = structure;
        this.request        = undefined;

        this.onerror = evnt => {
            console.log(evnt)
            alert('Error al intentar guardar o acceder la configuración de Booktrain')
        };

        this.onupgradeneeded = evnt => {
            let db = evnt.target.result
            this.structure.forEach(storaje => {
                try {
                    let objectStore = db.createObjectStore(storaje.name, storaje.keyPath)

                    storaje.indexes.forEach(index => {
                        objectStore.createIndex(index.name, index.name, index.type)
                    })

                    console.log('DB upgraded, object:', index.name)
                } catch (error) {
                    console.log(error)
                }
            })
        }
    }

    do(todo) {
        this.request                    = window.indexedDB.open(this.databasename, this.version);
        this.request.onerror            = this.onerror;
        this.request.onupgradeneeded    = this.onupgradeneeded;
        this.request.onsuccess          = todo;
    }

    getDataBaseName() {
        return this.databasename;
    }

    getVersion() {
        return this.version;
    }

    getStructure() {
        return this.structure;
    }

    countStores() {
        return this.structure.length;
    }
}

export default Insertar;
