export default ({setVista, carritoBase, referencia}) => {
    const cambioVista = () => {
        setVista('/vender')
        carritoBase([])
    }
    const cambioCarrito = () => {
        setVista('/carrito')
        carritoBase([])
    }

    return (
        <div className="fixed top-0 bottom-0 left-0 right-0 z-30 flex items-center justify-center">
            <div onClick={cambioCarrito} className="fixed top-0 bottom-0 left-0 right-0 bg-black opacity-30"></div>
            <div className="flex relative z-10 flex-col items-center w-full h-auto max-w-xl bg-white border border-gray-500 shadow rounded-xl pb-6 p-3.5">
                <div className="flex-wrap hidden w-4/5 mt-10 lg:flex place-content-between md:flex">
                    {/* <h2 className="rob-bold text-blue-button">Pago exitoso</h2> */}
                </div>
                <div className="flex flex-col items-center w-full">
                    <div className="flex flex-col items-center w-full my-5">
                        <h1 className="mb-3 text-2xl font-rob-bold text-green-button">VENTA REGISTRADA</h1>
                        {/* <span className="mb-6 text-base text-center md:font-rob-regularBT text-gray-bk">SE ENVIARÁ AL CORREO <b className="text-green-button text-uppercase">{datosContacto.email_contacto}</b> TU COMPROBANTE DE PAGO CUANDO SE SINCRONICEN LOS PAGOS</span> */}
                        <div className="flex flex-col mb-2 text-center">
                            <span className="text-base font-rob-light text-gray-bk">REFERENCIA</span>
                            <span className="text-xl font-rob-bold text-gray-bk text-uppdercase">{referencia}</span>
                        </div>
                    </div>
                    <div className="flex flex-col items-center justify-around w-full mt-4 lg:flex-row text-gray-bk">
                        <div className="box-border flex flex-col justify-center p-5 m-2 mx-2 text-center border rounded-md cursor-pointer w-80 lg:w-52 lg:h-24 hover:border-blue-400 hover:text-blue-400">
                            <a onClick={cambioVista}>REALIZAR OTRA VENTA</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
