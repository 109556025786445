import { useState, useEffect } from "react"
import monedas from "../../assets/img/monedas.svg"
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function Caja({cajaAbierta, urlHome, usuario, bookDB, peticionesFetch}) {
    const [ billete_20,     setBillete_20       ] = useState(localStorage.getItem('billete_20') == null     || localStorage.getItem('billete_20').length == 0?      0 : parseFloat(localStorage.getItem('billete_20')))
    const [ billete_50,     setBillete_50       ] = useState(localStorage.getItem('billete_50') == null     || localStorage.getItem('billete_50').length == 0?      0 : parseFloat(localStorage.getItem('billete_50')))
    const [ billete_100,    setBillete_100      ] = useState(localStorage.getItem('billete_100') == null    || localStorage.getItem('billete_100').length == 0?     0 : parseFloat(localStorage.getItem('billete_100')))
    const [ billete_200,    setBillete_200      ] = useState(localStorage.getItem('billete_200') == null    || localStorage.getItem('billete_200').length == 0?     0 : parseFloat(localStorage.getItem('billete_200')))
    const [ billete_500,    setBillete_500      ] = useState(localStorage.getItem('billete_500') == null    || localStorage.getItem('billete_500').length == 0?     0 : parseFloat(localStorage.getItem('billete_500')))
    const [ billete_1000,   setBillete_1000     ] = useState(localStorage.getItem('billete_1000') == null   || localStorage.getItem('billete_1000').length == 0?    0 : parseFloat(localStorage.getItem('billete_1000')))
    const [ conteo_monedas, setConteo_monedas   ] = useState(localStorage.getItem('conteo_monedas') == null || localStorage.getItem('conteo_monedas').length == 0?  0 : parseFloat(localStorage.getItem('conteo_monedas')))
    const [ monto_monedas,  setMonto_monedas    ] = useState(localStorage.getItem('monto_monedas') == null  || localStorage.getItem('monto_monedas').length == 0?   0 : parseFloat(localStorage.getItem('monto_monedas')))
    const [ modalCaja,      setModalCaja        ] = useState(false)
    const [ reloadM,        setReloadM          ] = useState(false)
    const [ carga,          setCarga            ] = useState(false)
    const [ modalOk,        setModalOk          ] = useState(true)
    const [ observaciones,  setObservaciones    ] = useState(localStorage.getItem('observaciones') == null? "" : localStorage.getItem('observaciones'))
    const [ tituloModal,    setTituloModal      ] = useState("")
    const [ textoModal,     setTextoModal       ] = useState("")

    const input20 = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('billete_20', val_input)
            setBillete_20(val_input)
        }
    }
    const input50 = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('billete_50', val_input)
            setBillete_50(val_input)
        }
    }
    const input100 = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('billete_100', val_input)
            setBillete_100(val_input)
        }
    }
    const input200 = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('billete_200', val_input)
            setBillete_200(val_input)
        }
    }
    const input500 = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('billete_500', val_input)
            setBillete_500(val_input)
        }
    }
    const input1000 = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('billete_1000', val_input)
            setBillete_1000(val_input)
        }
    }
    const inputMonedas = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('conteo_monedas', evnt.target.value)
            setConteo_monedas(evnt.target.value)
        }
    }
    const inputMontoMonedas = (evnt) => {
        let val_input = evnt.target.value
        if (!isNaN(val_input)) {
            localStorage.setItem('monto_monedas', evnt.target.value)
            setMonto_monedas(evnt.target.value)
        }
    }

    const inputObservaciones = (evnt) => {
        localStorage.setItem('observaciones', evnt.target.value)
        setObservaciones(evnt.target.value)
    }

    const totalCaja = () => {
        return Intl.NumberFormat('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(
            20 * billete_20 +
            50 * billete_50 +
            100 * billete_100 +
            200 * billete_200 +
            500 * billete_500 +
            1000 * billete_1000 +
            1 * monto_monedas
        );
    }

    const abrirModal = async (title, text) => {
        setCarga(false);
        setModalCaja(true);
        setTituloModal(title);
        setTextoModal(text);
    }

    const registrarReporte = () => {
        let consulta = bookDB;

        consulta.do(evnt => {
            let db          = evnt.target.result;
            let objStore    = db.transaction(["reportes"], "readwrite").objectStore("reportes").index("status");
            let request     = objStore.get('open');

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }

            request.onsuccess = (evnt) => {
                let fecha = new Date().getTime()
                let registro = evnt.target.result
                if (registro) {
                    registro.efectivo = {
                        billete_100:    billete_100,
                        billete_1000:   billete_1000,
                        billete_20:     billete_20,
                        billete_200:    billete_200,
                        billete_50:     billete_50,
                        billete_500:    billete_500,
                        conteo_monedas: conteo_monedas,
                        monto_monedas:  monto_monedas,
                        observaciones:  observaciones,
                    }
                    registro.codigo = fecha
                    registro.status = 'close'
    
                    let objStore2  = db.transaction(["reportes"], "readwrite").objectStore("reportes");
                    let getObjeto = objStore2.get(registro.id)
                    getObjeto.onsuccess = evnto => {
                        let resp = objStore2.put(registro)
    
                        resp.onerror = (evt) => {
                            console.log("Error de apertura", evt.target)
                        }
    
                        resp.onsuccess = () => {
                            console.log('Correcto')
                        }
                    }
                    getObjeto.onerror = evnt => {
                        console.log('Error');
                    }
                }
            }
        });
    }

    const borrarDB = async () => {
        localStorage.setItem('billete_20',      0)
        localStorage.setItem('billete_50',      0)
        localStorage.setItem('billete_100',     0)
        localStorage.setItem('billete_200',     0)
        localStorage.setItem('billete_500',     0)
        localStorage.setItem('billete_1000',    0)
        localStorage.setItem('conteo_monedas',  0)
        localStorage.setItem('monto_monedas',   0)
        localStorage.setItem('observaciones',   "")
        registrarReporte()
        let consulta = bookDB;
        console.log(bookDB.getStructure());
        consulta.do(evnt => {
            let db = evnt.target.result;
            let transaction = db.transaction("infocaja", "readwrite");
            let objStore = transaction.objectStore("infocaja");
            let request = objStore.getAll();

            request.onerror = (event) => {
                console.error(event.target);
                setModalOk(false);
                abrirModal('Error', 'Ocurrió un error inesperado');
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    event.target.result.map(caja => {
                        objStore.delete(caja.id)
                    })
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let transaction = db.transaction("inventario", "readwrite");
            let objStore = transaction.objectStore("inventario");
            let request = objStore.getAll();

            request.onerror = (event) => {
                console.error(event.target);
                setModalOk(false);
                abrirModal('Error', 'Ocurrió un error inesperado');
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    event.target.result.map(caja => {
                        objStore.delete(caja.id)
                    })
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let transaction = db.transaction("infocolegio", "readwrite");
            let objStore = transaction.objectStore("infocolegio");
            let request = objStore.getAll();

            request.onerror = (event) => {
                console.error(event.target);
                setModalOk(false);
                abrirModal('Error', 'Ocurrió un error inesperado');
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    event.target.result.map(caja => {
                        objStore.delete(caja.id)
                    })
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let transaction = db.transaction("infocarrito", "readwrite");
            let objStore = transaction.objectStore("infocarrito");
            let request = objStore.getAll();

            request.onerror = (event) => {
                console.error(event.target);
                setModalOk(false);
                abrirModal('Error', 'Ocurrió un error inesperado');
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    event.target.result.map(caja => {
                        objStore.delete(caja.id)
                    })
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let transaction = db.transaction("contenidocarrito", "readwrite");
            let objStore = transaction.objectStore("contenidocarrito");
            let request = objStore.getAll();

            request.onerror = (event) => {
                console.error(event.target);
                setModalOk(false);
                abrirModal('Error', 'Ocurrió un error inesperado');
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    event.target.result.map(caja => {
                        objStore.delete(caja.id)
                    })
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let transaction = db.transaction("cupones", "readwrite");
            let objStore = transaction.objectStore("cupones");
            let request = objStore.getAll();

            request.onerror = (event) => {
                console.error(event.target);
                setModalOk(false);
                abrirModal('Error', 'Ocurrió un error inesperado');
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    event.target.result.map(caja => {
                        objStore.delete(caja.id)
                    })
                }
            }
        });
    }

    const operacionCaja = (tipo_op) => {
        if (window.navigator.onLine) {
            setCarga(true);
            let cajaEstatus = {
                "billete_20":       billete_20,
                "billete_50":       billete_50,
                "billete_100":      billete_100,
                "billete_200":      billete_200,
                "billete_500":      billete_500,
                "billete_1000":     billete_1000,
                "conteo_monedas":   conteo_monedas,
                "monto_monedas":    monto_monedas,
                "observaciones":    observaciones
            }
            fetch((urlHome + '/api/venta_movil/caja/'+tipo_op), {
                method: 'POST',
                body: JSON.stringify(cajaEstatus),
                headers: {
                    'Authorization': 'Bearer ' + usuario.token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            }).then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    console.log(response.status);
                    if (response.status == 412) {
                        borrarDB().then(() => {
                            setModalOk(false)
                            abrirModal('Error', 'Al parecer la caja ya ha sido cerrada')
                            setReloadM(true)
                        })
                    }
                    throw new Error(response)
                }
            }).then(data => {
                if (data.success) {
                    setReloadM(true)
                    if (tipo_op == 'abrir') {
                        let consulta = bookDB;
                        consulta.do(event => {
                            let db = event.target.result;
                            let transaction = db.transaction(['infocaja'], 'readwrite');
                            let objetoCaja = transaction.objectStore("infocaja");
                            objetoCaja.add({
                                colegio: usuario.codigo_colegio,
                                info: data.data
                            })
                            transaction.oncomplete = function(evnt) {
                                peticionesFetch()
                            };
                            transaction.onerror = function(event) {
                                console.error(event.target.error.message, event.target);
                            };
                        });
                        consulta.do(event => {
                            let db = event.target.result;
                            let transaction = db.transaction(['reportes'], 'readwrite');
                            let objetoCaja = transaction.objectStore("reportes");
                            objetoCaja.add({
                                colegio:        usuario.codigo_colegio,
                                nombre_colegio: usuario.colegio,
                                status:         'open',
                                codigo:         new Date().getTime(),
                                efectivo:       cajaEstatus
                            })
                            transaction.oncomplete = function(evnt) {
                                peticionesFetch()
                            };
                            transaction.onerror = function(event) {
                                console.error(event.target.error.message, event.target);
                            };
                        });
                    } else {
                        borrarDB().then(() => {
                            setModalOk(true);
                            abrirModal('Caja cerrada', 'La operación de cierre se realizó exitosamente');
                        })
                    }
                } else {
                    setModalOk(false);
                    abrirModal('Error', data.message);
                    setReloadM(false);
                }
            }).catch(function(error) {
                console.log('Hubo un problema con la petición:\n', error);
                setModalOk(false)
                abrirModal('Error', 'No se pudo conectar con el servidor')
                setReloadM(true)
            });
        } else {
            setModalOk(false);
            abrirModal('Sin internet', 'Es necesario conectarse a internet para ' + tipo_op + ' la caja')
            setReloadM(false)
        }
    }

    const revizarSync = () => {
        let consulta = bookDB
        consulta.do(evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction("ventas", "readonly").objectStore("ventas").index("colegio")
            let request     = objStore.getAll()
            request.onerror = async (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    let ventas_sync = evnt.target.result.map(venta => {
                        let resp = venta.info
                        resp.contenido = resp.contenido.map(paq => {
                            let resp_paq = paq
                            resp_paq.libros = resp_paq.libros.map(libro => libro.id)
                            return resp_paq
                        })
                        resp.id = venta.id
                        resp.sync = venta.sync == 'true'
                        return resp
                    }).filter(venta => {
                        return !venta.sync
                    })
                    if (ventas_sync.length > 0) {
                        setModalOk(false);
                        abrirModal('Pedidos sin sincronizar', 'Es necesario que todos los pedidos estén sincronozados para cerrar la caja')
                    } else {
                        operacionCaja('cerrar')
                    }
                } else {
                    operacionCaja('cerrar')
                }
            }
        })
    }

    const intentoCerrar = () => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("contenidocarrito", "readonly").objectStore("contenidocarrito").index("estatus");
            let request = objStore.getAll('carrito');
            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    if (evnt.target.result.length > 0) {
                        setModalOk(false)
                        abrirModal('Carrito con elementos', 'Es necesario que el carrito esté vacio para poder cerrar la caja')
                    } else {
                        revizarSync()
                    }
                } else {
                    revizarSync()
                }
            }
        });
    }

    const recargaInfo = () => {
        if (reloadM) {
            location.reload()
        } else {
            setModalCaja(false)
        }
    }

    useEffect(() => {
        let idCaja = usuario.codigo_colegio;
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("infocaja", "readonly").objectStore("infocaja").index("colegio");
            let request = objStore.get(idCaja);
            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result != undefined) {
                    console.log('Caja ok');
                } else {
                    let closed = sessionStorage.getItem('aviso')
                    if (!closed) {
                        const SwalAlert = withReactContent(Swal)
                        SwalAlert.fire({
                            title: <p>Caja cerrada</p>,
                            html: (
                                <div className="w-full text-base text-justify text-gray-bk">
                                    <b className="text-lg">Es necesario estar conectado a internet para:</b>
                                    <ul className="pl-8 text-base list-disc list-outside">
                                        <li>Abrir la caja</li>
                                        <li>Cerrar la caja</li>
                                        <li>Sincronizar las ventas</li>
                                        <li>Cerrar la sesión</li>
                                        <li>Iniciar sesión</li>
                                    </ul>
                                    <hr className="my-4" />
                                    <b className="text-lg">Para evitar conflictos en el sistema:</b>
                                    <ul className="pl-8 text-base list-disc list-outside">
                                        <li>Asegurese de cerrar la caja cuando termine operaciones de venta.</li>
                                        <li>Cuando esté usando esta aplicación evite tener elementos en el carrito de la versión web de booktrain.</li>
                                    </ul>
                                    <br />
                                </div>
                            ),
                            icon:'warning',
                            allowOutsideClick: false,
                            confirmButtonColor: '#81b84e',
                        })
                        sessionStorage.setItem('aviso', 'close')
                    }
                }
            }
        });
    }, [])

    return (
        <div className="w-full overflow-x-hidden overflow-y-auto border-2 border-gray-300 rounded-md lg:h-contenedor">
            <div className={(modalCaja? "flex" : "hidden") + " fixed justify-center items-center z-20 top-0 bottom-0 left-0 right-0"}>
                <div onClick={() => recargaInfo()} className="fixed top-0 bottom-0 left-0 right-0 z-0 bg-black opacity-40"></div>
                <div className="relative z-10 w-full h-auto max-w-lg max-h-full overflow-x-hidden overflow-y-auto">
                    <div className="w-full h-auto px-4 py-10">
                        <div className="w-full h-auto px-16 pt-8 pb-6 text-center text-gray-600 bg-white rounded-lg">
                            <div className={"inline-flex items-center relative justify-center w-20 h-20 border-4 rounded-full " + (modalOk? 'border-green-circle' : 'border-red-200')}>
                                <div className={"w-6 h-10 mb-3 transform rotate-45 border-b-4 border-r-4 border-green-ok " + (modalOk? 'block' : 'hidden')}></div>
                                <div className={"w-1 h-11 m-auto top-0 right-0 left-0 bottom-0 absolute transform rotate-45 bg-red-400 " + (modalOk? 'hidden' : 'block')}></div>
                                <div className={"w-1 h-11 m-auto top-0 right-0 left-0 bottom-0 absolute transform -rotate-45 bg-red-400 " + (modalOk? 'hidden' : 'block')}></div>
                            </div>
                            <h1 className="pt-6 text-3xl font-rob-medium">{tituloModal}</h1>
                            <p className="pt-8 text-xl">{textoModal}</p>
                            <br />
                            <button onClick={() => recargaInfo()} className="px-4 py-3 text-white bg-blue-600 rounded-lg">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={(carga? "flex" : "hidden") + " fixed justify-center items-center z-20 top-0 bottom-0 left-0 right-0"}>
                <div className="fixed top-0 bottom-0 left-0 right-0 z-0 bg-black opacity-40"></div>
                <div className="relative z-10 w-full h-auto max-w-lg max-h-full overflow-x-hidden overflow-y-auto">
                    <div className="w-full px-4 py-10">
                        <div className="flex items-center justify-center w-full px-16 pt-8 pb-6 text-center text-gray-600 bg-white rounded-lg h-60">
                            <svg className="w-10 h-10 -ml-1 animate-spin text-green-button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                            </svg>
                            <div className="ml-3 select-none text-gray-bk font-rob-bold">Cargando...</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="flex flex-col justify-between w-full lg:h-full">

                <section className="flex flex-col justify-between w-full h-auto p-4 lg:flex-row">

                    <div className="w-full pt-4 lg:w-1/2">

                        <div className="w-full">

                            <h2 className="text-2xl text-green-book font-rob-bold">
                                {(cajaAbierta? 'CIERRE' : 'APERTURA') + ' DE CAJA | ' + usuario.colegio}
                            </h2>

                        </div>

                        <div className="flex items-center justify-center w-full mt-10 h-3/4 lg:mt-0">

                            <img src={monedas} alt="monedas" className="relative w-1/4 lg:w-1/3" />

                        </div>

                    </div>

                    <div className="flex items-center w-full h-full py-4 lg:w-1/2 ">

                        <div className="flex flex-col px-4 lg:border-l border-gray-bk">

                            <div className="flex flex-col w-full lg:flex-row ">

                                <div className="w-full lg:w-2/3">

                                    <h2 className="mt-0 text-2xl text-blue-book font-rob-bold"> Billetes </h2>

                                    <div className="relative flex flex-wrap w-full py-3 lg:pr-4">

                                        <div className="flex flex-col w-1/2 pr-2">
                                            <label htmlFor="billete_20" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                $20
                                            </label>
                                            <input value={billete_20} id="billete_20" onChange={input20} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>
                                        <div className="flex flex-col w-1/2 pl-2">
                                            <label htmlFor="billete_50" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                $50 
                                            </label>
                                            <input value={billete_50} id="billete_50" onChange={input50} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>


                                            {/* 100 200 */}

                                        <div className="flex flex-col w-1/2 pr-2">
                                            <label htmlFor="billete_100" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                $100
                                            </label>
                                            <input value={billete_100} id="billete_100" onChange={input100} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>
                                        <div className="flex flex-col w-1/2 pl-2">
                                            <label htmlFor="billete_200" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                $200 
                                            </label>
                                            <input value={billete_200} id="billete_200" onChange={input200} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>


                                        {/* 500 1000 */}

                                        <div className="flex flex-col w-1/2 pr-2">
                                            <label htmlFor="billete_500" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                $500
                                            </label>
                                            <input value={billete_500} id="billete_500" onChange={input500} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>

                                        <div className="flex flex-col w-1/2 pl-2">
                                            <label htmlFor="billete_1000" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                $1000 
                                            </label>
                                            <input value={billete_1000} id="billete_1000" onChange={input1000} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>


                                    </div>
                                </div>

                                <div className="w-full lg:w-1/3 ">

                                    <h2 className="mt-0 text-2xl text-blue-book font-rob-bold"> Monedas </h2>

                                    <div className="relative flex flex-wrap w-full py-3 h-1/2">

                                        <div className="w-full">
                                            <label htmlFor="conteo_monedas" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                Cantidad 
                                            </label>
                                            <input value={conteo_monedas} id="conteo_monedas" onChange={inputMonedas} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>

                                        <div className="w-full">
                                            <label htmlFor="monto_monedas" className="relative self-start uppercase text-gray-bk font-rob-light">
                                                Monto 
                                            </label>
                                            <input value={monto_monedas} id="monto_monedas" onChange={inputMontoMonedas} min="0" type="number" step="any" className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none" />
                                        </div>

                                    </div>

                                </div>
                                
                                

                            </div>


                            <div className="">
                                <h2 className="text-lg uppercase text-gray-bk font-rob-bold">Observaciones</h2>
                                <textarea value={observaciones} onInput={inputObservaciones} className="w-full p-2 my-2 border border-gray-300 rounded-md appearance-none min-h-text-area h-28"></textarea>
                                <span className="text-lg uppercase text-gray-bk font-rob-bold">Total: ${totalCaja()}</span>
                            </div>

                        </div>

                    </div>            

                </section>

                <div className="flex flex-col items-center justify-center w-full p-5 ">
                    <a onClick={() => operacionCaja('abrir')} className={(cajaAbierta? "hidden" : "flex") + " relative items-center justify-center w-60 max-w-full p-3 text-white uppercase rounded-md cursor-pointer bg-green-button font-rob-bold"}>
                        <span>REGISTRAR APERTURA</span>
                    </a>

                    <a onClick={() => intentoCerrar()} className={(cajaAbierta? "flex" : "hidden") + " relative items-center justify-center w-60 max-w-full p-3 text-white uppercase rounded-md cursor-pointer bg-green-button font-rob-bold"}>
                        <span>REGISTRAR CIERRE</span>
                    </a>
                    {/* <a onClick={() => registrarReporte()} className={(true? "flex" : "hidden") + " relative items-center justify-center w-60 max-w-full p-3 text-white uppercase rounded-md cursor-pointer bg-green-button font-rob-bold"}>
                        <span>Test</span>
                    </a> */}
                </div>

            </div>

        </div>
    )
}

export default Caja