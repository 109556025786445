import { Splide, SplideSlide } from '@splidejs/react-splide'
import '@splidejs/splide/dist/css/splide.min.css'

const Notificaciones = ({avisos}) => {
    return (

            <div className="w-full relative flex flex-wrap flex-col justify-evenly content-center lg:p-3 p-5 pb-5 lg:h-full h-auto">

                <div className="w-full relative flex flex-wrap justify-center content-center items-center h-1/6">

                    <h2 className="lg:text-3xl text-2xl font-bold text-blue-book w-full text-center">
                            
                        PIZARRÓN DE NOTIFICACIONES

                    </h2>

                </div>


                <Splide 
                    className="w-full h-5/6"
                    options={ {
                        type : 'slide',
                        rewind : true,
                        rewindSpeed: 500,
                        perPage: 1,
                        navigation:true,
                        arrows:false,
                        pauseOnHover:true,
                        drag:true,
                        autoplay:true,
                        interval:6000,
                        waitForTransition:true,
                        keyboard:true,
                        classes:{
                            pagination: 'lg:absolute lg:bottom-0 isactiveBt flex justify-center w-full z-10',
                            page      : 'w-3 h-3 mx-2 rounded-full bg-gray-300 hover:bg-gray-400',  
                        }  
                    } }
                >

            {
            
            avisos.map((aviso, index) => {
                    return (
                        <SplideSlide key={index} className="flex justify-center items-center"> 
                            <h3 className="lg:text-3xl rob-bold lg:w-10/12 w-full text-center text-green-book text-2xl py-5 uppercase">
                                {aviso}
                            </h3>
                        </SplideSlide>
                    )
                })
                
            }

            </Splide>
            

            </div>
        )
    }

export default Notificaciones
