import { useState } from "react"

export default ({setPasarela, setInicioPasarela, datosContacto, setDatosContacto}) => {
    const [ nombre_contacto,    setNombre           ] = useState(datosContacto.nombre_contacto)
    const [ apellidos_contacto, setApellidos        ] = useState(datosContacto.apellidos_contacto)
    const [ tel_contacto,       setTel              ] = useState(datosContacto.tel_contacto)
    const [ email_contacto,     setEmail            ] = useState(datosContacto.email_contacto)
    const [ email_licencias,    setEmailLicencias   ] = useState(datosContacto.email_licencias)

    const [ validaNombre,           setValidaNombre             ] = useState(true)
    const [ validaApellido,         setValidaApellido           ] = useState(true)
    const [ validaTel,              setValidaTel                ] = useState(true)
    const [ validaCorreo,           setValidaCorreo             ] = useState(true)
    const [ validaCorreoLicencias,  setValidaCorreoLicencias    ] = useState(true)

    const validaName = /^[a-zA-ZÑñÁáÉéÍíÓóÚúÜü\s]+$/
    const validaMail = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i

    const validacion = (
        nombre_contacto.length      > 1     &&
        validaName.test(nombre_contacto)    &&
        apellidos_contacto.length   > 1     &&
        validaName.test(apellidos_contacto) &&
        tel_contacto.length         == 10   &&
        !isNaN(tel_contacto)                &&
        email_contacto.length       > 0     &&
        validaMail.test(email_contacto)
    )

    const valNombre = (evnt) => {
        let value = evnt.target.value
        setNombre(value)
        setValidaNombre(value.length > 1 && validaName.test(value))
    }
    const valApellido = (evnt) => {
        let value = evnt.target.value
        setApellidos(value)
        setValidaApellido(value.length > 1 && validaName.test(value))
    }
    const valTelefono = (evnt) => {
        let value = evnt.target.value
        setTel(value)
        setValidaTel(value.length == 10 && !isNaN(value))
    }
    const valCorreo = (evnt) => {
        let value = evnt.target.value
        setEmail(value)
        setValidaCorreo(value.length > 0 && validaMail.test(value))
    }
    const valCorreoLicencias = (evnt) => {
        let value = evnt.target.value
        setEmailLicencias(value)
        setValidaCorreoLicencias(value.length == 0 || validaMail.test(value))
    }

    const siguiente = () => {
        if (validacion){
            setPasarela('/pago')
            setDatosContacto({
                nombre_contacto:    nombre_contacto,
                apellidos_contacto: apellidos_contacto,
                tel_contacto:       tel_contacto,
                email_contacto:     email_contacto,
                email_licencias:    email_licencias
            })
        }
    }

    return (
        <div className="flex flex-col items-center w-full h-auto max-w-xl py-6 border border-gray-500 shadow rounded-xl">
            <div className="flex flex-wrap w-full px-3 mt-0 md:w-4/5 md:px-0 md:mt-10 place-content-between">
                <h2 className="uppercase font-rob-bold text-green-button">Datos de contacto</h2>
                <div>
                    <span className="font-rob-light text-gray-bk">1 - 2</span>
                </div>
            </div>
            <div className="flex justify-around w-full h-auto mt-12">
                <div className="relative flex flex-col w-full h-auto px-3 mb-6 md:w-4/5 md:px-0">
                    <div className="flex flex-col mb-4">
                        <label htmlFor="first_name" className="mb-2 text-left uppercase text-gray-bk font-rob-light">NOMBRE *</label>
                        <input defaultValue={nombre_contacto} onChange={valNombre} type="text" className="px-3 py-2 border text-grey-darkest font-rob-light" />
                        <small className={"text-gray-bk w-full " + (validaNombre? "hidden" : "inline-block")}><span>El formato es inválido</span></small>
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="first_name" className="mb-2 text-left uppercase text-gray-bk font-rob-light">APELLIDOS *</label>
                        <input defaultValue={apellidos_contacto} onChange={valApellido} type="text" className="px-3 py-2 border text-grey-darkest font-rob-light" />
                        <small className={"text-gray-bk w-full " + (validaApellido? "hidden" : "inline-block")}><span>El formato es inválido</span></small>
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="first_name" className="mb-2 text-left uppercase text-gray-bk font-rob-light">CELULAR *</label>
                        <input defaultValue={tel_contacto} onChange={valTelefono} type="text" className="px-3 py-2 border text-grey-darkest font-rob-light" />
                        <small className={"text-gray-bk w-full " + (validaTel? "hidden" : "inline-block")}><span>El telefono es inválido</span></small>
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="first_name" className="mb-2 text-left uppercase text-gray-bk font-rob-light">CORREO *</label>
                        <input defaultValue={email_contacto} onChange={valCorreo} type="text" className="px-3 py-2 border text-grey-darkest font-rob-light" />
                        <small className={"text-gray-bk w-full " + (validaCorreo? "hidden" : "inline-block")}><span>El correo es invalido</span></small>
                    </div>
                    <div className="flex flex-col mb-4">
                        <label htmlFor="first_name" className="mb-2 text-left uppercase text-gray-bk font-rob-light">CORREO PARA RECEPCIÓN DE LICENCIAS DIGITALES</label>
                        <input defaultValue={email_licencias} onChange={valCorreoLicencias} type="text" className="px-3 py-2 border text-grey-darkest font-rob-light" />
                        <small className={"text-gray-bk w-full " + (validaCorreoLicencias? "hidden" : "inline-block")}><span>El correo es invalido</span></small>
                    </div>
                    <div className="relative flex flex-col w-full h-5 mb-4 text-right">
                        <span className="rob-light text-gray-bk">*Campos son obligatorios</span>
                    </div>
                    <div className="relative flex flex-wrap content-end w-full mt-auto mb-4 h-1/3">
                        <div className="relative flex justify-center w-full">
                            <button onClick={siguiente} type="submit" className={"appearance-none relative block text-center py-1 px-3 w-1/2 my-1.5 rounded-md bg-gray-400 border border-solid border-gray-400 text-white lg:text-lg md:text-base sm:text-sm " + (validacion? "hover:bg-white hover:border-green-button bg-green-button hover:text-green-button cursor-pointer" : "opacity-50 cursor-default")}>
                                <span className="rob-bold">SIGUIENTE</span>
                            </button>
                        </div>
                        <div className="relative flex justify-center w-full">
                            <div onClick={() => setInicioPasarela(false)} className="appearance-none relative block text-center py-1 px-3 w-1/2 my-1.5 rounded-md text-gray-bk border border-solid border-gray-400 cursor-pointer hover:bg-white hover:border-blue-button hover:text-blue-button lg:text-lg md:text-base sm:text-sm">
                                <span className="rob-bold">REGRESAR</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
