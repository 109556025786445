
const Ventas = ({Seccion, setGrado, Grado }) => {


    const seccionEscolar = (grados, nombre) => {
        grados.seccionEducativa = nombre
        setGrado(grados)
    }


    return (

            <div className="h-1/2 w-full px-5 pb-5 grid grid-cols-2 gap-4 items-start content-start overflow-y-auto scrollBar-BK">
                {
                    Seccion.map(( element, index) => {
                        let {nombre, niveles} = element
                        return(
                            <div key={index}>
                                <div onClick={() => seccionEscolar(niveles, nombre)} className="cursor-pointer break-word appearance-none uppercase relative block text-center py-6 px-3 my-1.5 rounded-md border-gray-400 border border-solid text-gray-bk  hover:bg-white hover:border-blue-400 hover:text-blue-400 lg:text-md md:text-sm sm:text-sm h-auto">
                                    <span className="font-rob-light ">{ nombre }</span>
                                </div>
                            </div>
                        )
                    })  
                }

            </div> 
    )
}

export default Ventas
