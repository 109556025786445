import ReactDOM  from "react-dom";
import App from "./components/App";   
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import serv from "./service-worker"
// import reportWebVitals from './reportWebVitals';
// Ya no necesitas importar react ya que en el babel lo hace de manera automatica 
if (!window.indexedDB) {
    window.alert("Su navegador no soporta una versión estable de indexedDB");
}

ReactDOM.render(<App />, document.getElementById("root"));



//https://www.youtube.com/watch?v=lNMxjHCLpbk

