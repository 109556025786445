import { useState, useEffect } from 'react'
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import logo_principal from "../../assets/img/logo_principal.svg"
import flecha_abajo from "../../assets/img/flecha_abajo.svg"
import img_user from "../../assets/img/img_user.svg"
import mis_pedidos_svg from "../../assets/img/mis_pedidos.svg"
import soporte_svg from "../../assets/img/soporte.svg"
import comprar_svg from "../../assets/img/comprar.svg"
import reporte_svg from "../../assets/img/reporte.svg"
import salir_svg from "../../assets/img/salir.svg"
import carrito_svg from "../../assets/img/carrito.svg"
import sync_svg from "../../assets/img/sync_icon.svg"


function Header({setLogued, vista, setVista, carrito, usuario, cajaAbierta, urlHome, bookDB, token}) {
    const [ vermenu,        setVermenu      ] = useState(false)
    const [ verHeader,      setVerHeader    ] = useState(false)
    const [ modal,          setModal        ] = useState(false)
    const [ visible,        setVisible      ] = useState(false)
    const [ modalOk,        setModalOk      ] = useState(true)
    const [ tituloModal,    setTituloModal  ] = useState("")
    const [ textoModal,     setTextoModal   ] = useState("")
    const [ online,         setOnline       ] = useState(window.navigator.onLine)

    const verIndicador = (evt) => {
        setOnline(window.navigator.onLine)
        setVisible(true)
        setTimeout(() => {
            setVisible(false)
        }, 2000)
    }

    window.addEventListener('online',  verIndicador)
    window.addEventListener('offline', verIndicador)

    const marcarSync = (data_registro) => {
        let id_registro = data_registro.id_registro
        let consulta = bookDB;
        consulta.do(evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction(["ventas"], "readwrite").objectStore("ventas")
            let request     = objStore.get(id_registro)

            request.onerror = (event) => {
                console.error("Error de borrado", event.target)
            }

            request.onsuccess = (evnt) => {
                let venta = evnt.target.result
                venta.sync = "true"
                venta.serie = data_registro.serie
                venta.folio = data_registro.folio
                let resp = objStore.put(venta)

                resp.onerror = (evt) => {
                    console.error("Error de apertura", evt.target)
                }
                resp.onsuccess = () => {
                    console.log('Venta ' + venta.serie + venta.folio + ' registrada')
                }
            }
        })
    }

    // FUNCION PARA SINCRONIZAR
    const syncBD = async (verModal) => {
        const SwalLoad = withReactContent(Swal)
        const SwalAlert = withReactContent(Swal)
        const Toast = withReactContent(Swal.mixin({
            toast: true,
            position: 'top',
            showConfirmButton: false,
            // timer: 3000,
            timerProgressBar: false,
        }))
        let consulta = bookDB;
        consulta.do(evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction("ventas", "readonly").objectStore("ventas").index("colegio")
            let request     = objStore.getAll()
    
            request.onerror = async (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result && window.navigator.onLine) {
                    let ventas_sync = evnt.target.result.map(venta => {
                        let resp = venta.info
                        resp.contenido = resp.contenido.map(paq => {
                            let resp_paq = paq
                            resp_paq.libros = resp_paq.libros.map(libro => libro.id)
                            return resp_paq
                        })
                        resp.id = venta.id
                        resp.sync = venta.sync == 'true'
                        return resp
                    }).filter(venta => {
                        return !venta.sync
                    })
                    if (ventas_sync.length > 0) {
                        if (verModal) {
                            SwalLoad.fire({
                                title: <p>Sincronización en curso</p>,
                                html: (
                                    <div className="flex items-center justify-center w-auto max-w-full h-28">
                                        <svg className="w-10 h-10 -ml-1 animate-spin text-green-button" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <div className="ml-3 select-none text-gray-bk font-rob-bold">Cargando...</div>
                                    </div>
                                ),
                                showConfirmButton: false,
                                allowOutsideClick: false,
                            })
                        } else {
                            Toast.fire({
                                html: <p className="text-center font-rob-bold">Sincronización en curso...</p>
                            })
                        }
                        Promise.all(ventas_sync.map(venta => fetch((urlHome + '/api/venta_movil/pagar'), {
                            method: 'POST',
                            body: JSON.stringify(venta),
                            headers: {
                                'Authorization': 'Bearer ' + token,
                                'Content-Type': 'application/json',
                                'Accept': 'application/json'
                            }
                        }))).then(responses => {
                            return Promise.all(responses.map(response => response.json()));
                        }).then(data => {
                            let errores = ''
                            data.map(resp => {
                                if (resp.success) {
                                    marcarSync(resp.data)
                                } else {
                                    errores = resp.message
                                }
                            })
                            if (verModal) {
                                if (errores.length > 0) {
                                    SwalLoad.close()
                                    SwalAlert.fire({
                                        title: <p>Sincronización incorrecta</p>,
                                        html: errores,
                                        icon:'warning',
                                        confirmButtonColor: '#df5617',
                                    })
                                } else {
                                    SwalLoad.close()
                                    SwalAlert.fire({
                                        title: <p>Sincronización finalizada</p>,
                                        html: <p>La sincronización se realizo correctamente</p>,
                                        icon:'success',
                                        confirmButtonColor: '#2563EB',
                                    })
                                }
                            } else {
                                Toast.fire({
                                    timer: 3000,
                                    title: (errores.length > 0? 'Sincronización incorrecta' : 'Sincronización finalizada'),
                                    icon: (errores.length > 0? 'warning' : 'success'),
                                })
                            }
                        }).catch((error) => {
                            console.log('Hubo un problema con la petición:\n', error)
                        });
                    } else {
                        if (verModal) {
                            // SwalLoad.close()
                            SwalAlert.fire({
                                title: <p>Sin ventas para sincronizar</p>,
                                html: <p>No se encontraron ventas para sincronizar.</p>,
                                icon:'warning',
                                confirmButtonColor: '#df5617',
                            })
                        }
                    }
                } else if (!window.navigator.onLine) {
                    if (verModal) {
                        SwalAlert.fire({
                            title: <p>Offline</p>,
                            html: <p>No se puede conectar a una red</p>,
                            icon:'error',
                            confirmButtonColor: '#dc2626',
                        })
                    }
                } else {
                    if (verModal) {
                        SwalAlert.fire({
                            title: <p>Sin ventas para sincronizar</p>,
                            html: <p>No se encontraron ventas para sincronizar.</p>,
                            icon:'warning',
                            confirmButtonColor: '#df5617',
                        })
                    }
                }
            }
        })
    }

    const cerrarSesion = async () => {
        if(online) {
            fetch((urlHome + '/api/logout'), {
                headers: {
                    'Authorization': 'Bearer ' + usuario.token
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    localStorage.removeItem('usuario')
                    setVista('/caja')
                    setLogued(false)
                } else {
                    setModalOk(false);
                    setModal(true)
                    setTituloModal('Error')
                    setTextoModal(data.message)
                }
            })
            .catch(error => {
                setModalOk(false);
                setModal(true)
                setTituloModal('Error')
                setTextoModal('Lo sentimos, ocurrió un error inesperado')
                console.error(error);
            })
        } else {
            setModalOk(false);
            setModal(true)
            setTituloModal('Sin internet')
            setTextoModal('Es necesario conectarse a internet para cerrar la sesión')
        }
    }

    const cantidadCarrito = () => {
        if (carrito > 0) {
            return (
                <div className="absolute top-0 right-0 flex items-center justify-center w-auto h-6 px-2 text-xs text-white rounded-full bg-red-notification">
                    <span>{carrito}</span>
                </div>
            )
        }
    }

    const botonCarrito = () => {
        if (cajaAbierta) {
            return (
                <button onClick={() => setVista('/carrito')} className={(vista == '/carrito'? "bg-green-button" : "bg-blue-button") + " relative flex items-center justify-start lg:justify-center lg:h-24 h-auto border-b-2 border-gray-300 w-full lg:w-24 py-1 px-2 mb-1 lg:my-auto"}>
                    {cantidadCarrito()}
                    <img className="w-auto h-8 mr-3 sm:h-10" src={carrito_svg} alt="Carrito" />
                    <span className="block text-base font-rob-light lg:hidden">Carrito</span>
                </button>
            )
        }
    }

    const botonSync = () => {
        if (cajaAbierta) {
            return (
                <button onClick={() => syncBD(true)} className="relative flex items-center justify-start w-full h-auto px-2 py-1 mt-1 border-b-2 border-gray-300 bg-red-book activo:bg-red-bookTouch lg:justify-center lg:h-24 lg:w-24 lg:my-auto lg:hidden">
                    <img src={sync_svg} alt="sync svg" className="w-8 h-8 mr-3"/>
                    <span className="text-base font-rob-light">Sincronizar</span>
                </button>
            )
        }
    }

    const botonHeader = (ruta, texto, img) => {
        if ((cajaAbierta || ruta == '/caja' || ruta == '/reporte') && vista != '/') {
            return (
                <button onClick={() => setVista(ruta)} className={(vista == ruta? "border-b-green-bk" : "") + " relative flex items-center justify-start lg:justify-center lg:h-24 h-auto text-center w-full lg:w-36 py-0.5 my-1 lg:my-auto lg:py-7 lg:hover:text-green-button"}>
                    <img src={img} alt={texto} className="inline-block h-5 pr-4 lg:hidden" />
                    <span>{texto}</span>
                </button>
            )
        }
    }

    const cerrarMenu = () => {
        setVermenu(false)
        setVerHeader(false)
    }

    useEffect(() => syncBD(false), [])

    return (
        <div className="relative w-full h-24 print:hidden">
            <div className={(modal? "flex" : "hidden") + " fixed justify-center items-center z-30 top-0 bottom-0 left-0 right-0"}>
                <div onClick={() => setModal(false)} className="fixed top-0 bottom-0 left-0 right-0 z-0 bg-black opacity-40"></div>
                <div className="relative z-10 w-full h-auto max-w-lg max-h-full overflow-x-hidden overflow-y-auto">
                    <div className="w-full h-auto px-4 py-10">
                        <div className="w-full h-auto px-16 pt-8 pb-6 text-center text-gray-600 bg-white rounded-lg">
                            <div className={"inline-flex relative items-center justify-center w-20 h-20 border-4 rounded-full " + (modalOk? 'border-green-circle' : 'border-red-200')}>
                                <div className={"w-6 h-10 mb-3 transform rotate-45 border-b-4 border-r-4 border-green-ok " + (modalOk? 'block' : 'hidden')}></div>
                                <div className={"w-1 h-11 m-auto top-0 right-0 left-0 bottom-0 absolute transform rotate-45 bg-red-400 " + (modalOk? 'hidden' : 'block')}></div>
                                <div className={"w-1 h-11 m-auto top-0 right-0 left-0 bottom-0 absolute transform -rotate-45 bg-red-400 " + (modalOk? 'hidden' : 'block')}></div>
                            </div>
                            <h1 className="pt-6 text-3xl font-rob-medium">{tituloModal}</h1>
                            <p className="pt-8 text-xl">{textoModal}</p>
                            <br />
                            <button onClick={() => setModal(false)} className="px-4 py-3 text-white bg-blue-600 rounded-lg">OK</button>
                        </div>
                    </div>
                </div>
            </div>

            <div className="fixed z-10 flex items-center w-full h-24 p-4 overflow-hidden bg-white shadow lg:shadow-none lg:py-0 lg:overflow-visible xl:px-16">
                <div onClick={() => setVerHeader(true)} className="relative flex flex-col justify-between h-10 p-1 cursor-pointer w-11 lg:hidden">
                    <div className="w-full h-1.5 bg-green-button"></div>
                    <div className="w-full h-1.5 bg-green-button"></div>
                    <div className="w-full h-1.5 bg-green-button"></div>
                </div>

                <div onClick={cerrarMenu} className={(verHeader? "block" : "hidden") + " fixed lg:hidden left-0 right-0 top-0 bottom-0 bg-black transition-all z-20 opacity-30 duration-200"}></div>

                <div className={(verHeader? "-left-0.5" : "-left-64") + " fixed z-30 lg:z-0 transition-all duration-200 lg:left-auto shadow lg:shadow-none top-0 lg:top-auto flex flex-col items-center justify-between w-64 max-w-full h-screen border-b-0 border-gray-300 lg:w-full lg:relative lg:flex-row lg:h-full bg-green-button lg:bg-white lg:border-b-2"}>
                    <div className="flex flex-col items-start justify-around w-full px-3 py-4 lg:p-auto lg:w-96 lg:items-center lg:flex-row lg:space-x-10 2xl:space-x-32">
                        <div className="flex items-center w-full h-10 lg:w-auto">
                            <img onClick={() => setVista(vista != '/'? '/caja' : '/')} src={logo_principal} alt="" className="hidden h-full cursor-pointer lg:block" />
                            <div onClick={() => setVerHeader(false)} className="relative w-8 h-8 cursor-pointer lg:hidden">
                                <div className="absolute top-0 bottom-0 left-0 right-0 h-1 m-auto transform -rotate-45 bg-white w-7"></div>
                                <div className="absolute top-0 bottom-0 left-0 right-0 h-1 m-auto transform rotate-45 bg-white w-7"></div>
                            </div>
                        </div>
                    </div>

                    <nav className="flex flex-col justify-around w-full max-w-2xl px-4 text-lg text-white lg:text-sm lg:w-1/2 lg:flex-row lg:text-gray-bk font-rob-medium lg:font-rob-light">

                        {botonHeader('/vendidos',   'VENDIDOS',                                     mis_pedidos_svg )}
                        {botonHeader('/caja',       (cajaAbierta? 'CERRAR' : 'ABRIR')+' CAJA',      soporte_svg     )}
                        {botonHeader('/vender',     'VENDER',                                       comprar_svg     )}
                        {botonHeader('/reporte',    'REPORTES',                                     reporte_svg     )}

                    </nav>

                    <div className={(cajaAbierta? "lg:justify-between " : "") + "relative z-30 flex flex-col items-start justify-end w-full pb-6 text-white lg:pb-0 h-menu-movil lg:h-full lg:items-center lg:w-96 lg:text-gray-bk lg:flex-row xl:space-x-10"}>
                        {botonSync()}
                        {botonCarrito()}
                        <button onClick={() => cerrarSesion()} className="relative flex items-center justify-start w-full h-auto p-4 py-1.5 text-lg text-center text-white uppercase border-b border-white font-rob-medium lg:hidden lg:justify-center lg:h-24 lg:w-36 lg:py-7 lg:hover:text-green-button">
                            <img src={salir_svg} alt="Cerrar sesión" className="inline-block h-4 pr-4" />
                            <span>Cerrar sesión</span>
                        </button>
                        <div className="relative z-30 items-center justify-between hidden lg:flex w-60">
                            <div onClick={cerrarMenu} className={(vermenu? "block" : "hidden") + " fixed left-0 right-0 top-0 bottom-0 z-20"}></div>
                            <button onClick={() => setVermenu(!vermenu)} type="button" aria-expanded="false" className="inline-flex items-center justify-between w-full text-base font-medium text-gray-500 bg-white rounded-md group hover:text-gray-900 focus:outline-none">
                                <a className="flex items-center p-3 -m-3 rounded-lg">
                                    <img src={img_user} alt="" className="w-auto h-10" />
                                    <div className="ml-4">
                                        <p className="text-xs text-gray-500 uppercase">{usuario.name}</p>
                                    </div>
                                </a>
                                <img src={flecha_abajo} className="w-2 mr-3.5" />
                            </button>
                            <div className={"relative lg:absolute cursor-pointer left-0 right-0 z-30 px-2 transform top-full mt-7 sm:px-0 block " + (vermenu? 'lg:block' : 'lg:hidden')}>
                                <div className="relative border border-gray-400 shadow-lg">
                                    <div className="absolute w-3 h-3 border-t border-r bg-white border-gray-400 right-3 -top-1.5 z-10 transform -rotate-45"></div>
                                    <div className="relative grid gap-10 px-5 py-6 text-sm text-gray-500 bg-white rob-light sm:gap-8 sm:p-6">
                                        <a onClick={() => syncBD(true)} className="flex items-center p-3 -m-3 rounded-lg hover:bg-blue-button hover:text-white">
                                            <div>
                                                <p>Sincronizar</p>
                                            </div>
                                        </a>
                                    </div>
                                    <div className="relative grid gap-10 px-5 py-6 text-sm text-gray-500 bg-white rob-light sm:gap-8 sm:p-6">
                                        <a onClick={() => cerrarSesion()} className="flex items-center p-3 -m-3 rounded-lg hover:bg-blue-button hover:text-white">
                                            <div>
                                                <p>Cerrar sesión</p>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div onMouseOver={() => setVisible(true)} onMouseOut={() => setVisible(false)} className={"fixed h-7 border-2 border-gray-bk overflow-hidden transition-all duration-300 text-xs text-white rounded-md flex items-center justify-start p-1 right-4 xl:right-16 top-8 lg:top-0.5 z-10 " + (online? "bg-green-button" : "bg-red-book") + (visible? " w-32" : " w-7")}>
                    <div className="relative flex items-center justify-center w-5 h-5 pointer-events-none">
                        <div className={"w-1.5 h-4 mb-0.5 transform rotate-45 border-b-2 border-r-2 border-white " + (online? 'block' : 'hidden')}></div>
                        <div className={"w-0.5 h-4 m-auto top-0 right-0 left-0 bottom-0 absolute transform rotate-45 bg-white " + (online? 'hidden' : 'block')}></div>
                        <div className={"w-0.5 h-4 m-auto top-0 right-0 left-0 bottom-0 absolute transform -rotate-45 bg-white " + (online? 'hidden' : 'block')}></div>
                    </div>
                    <span className="absolute px-2 pointer-events-none left-6 whitespace-nowrap font-rob-bold">
                        { online? 'Conectado' : 'Sin internet...' }
                    </span>
                </div>
            </div>
        </div>
    )
}

export default Header
