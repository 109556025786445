import { useState } from 'react'
import "../assets/css/App.css"
import "../assets/sass/App.scss"
import Index from './Secciones/Index'
import Login from './Secciones/Login'

function App() {
    // const urlHome = 'https://demo.booktrain.com.mx'
    // const urlHome = 'http://127.0.0.1:8000'
    // console.log('Hostname db:', window.location.hostname)
    const urlHome = window.location.hostname == 'vm.booktrain.com.mx'? 'https://booktrain.com.mx' : 'http://127.0.0.1:8000'

    const [ usuario,    setUsuario  ] = useState(JSON.parse(localStorage.getItem('usuario')))
    const [ logued,     setLogued   ] = useState(usuario != undefined)

    const renderPantalla = logued? <Index setLogued={setLogued} urlHome={urlHome} usuario={usuario} /> : <Login setLogued={setLogued} urlHome={urlHome} setUsuario={setUsuario} />
    // console.log(Math.round(+new Date / 1e3 | 0));
    // console.log(new Date().getTime());

    return (
        <div className="relative w-full">
            { renderPantalla }
        </div>
    );
}

export default App
