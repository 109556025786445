import { useEffect, useState } from "react"
import Pasarela from './Carrito/Pasarela';
import bote from "../../assets/img/bote.svg"
import Loading from "./Loading";

function Carrito({setVista, metodosPago, carritoBase, bookDB, usuario, colegio}) {
    const [ inicioPasarela, setInicioPasarela   ] = useState(false)
    const [ mensaje,        setMensaje          ] = useState('')
    const [ carrito,        setCarrito          ] = useState([])
    const [ subtotal,       setSubtotal         ] = useState(0)
    const [ cargado,        setCargado          ] = useState(false)
    const [ inventarioID,   setInventarioID     ] = useState(0)

    let lenghtCar = carrito.length
    let subtotalCarrito = 0
    let inputsValidos = true

    const getCarrito = async (ifupdate) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db          = evnt.target.result
            let objStore    = db.transaction("inventario", "readonly").objectStore("inventario").index("colegio")
            let request     = objStore.get(colegio.codigo)

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    setInventarioID(event.target.result.id)
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction(["contenidocarrito"], "readonly").objectStore("contenidocarrito").index("estatus");
            let request = objStore.getAll("carrito");

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }

            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    if (ifupdate) {
                        carritoBase(evnt.target.result)
                    } else {
                        setCarrito(evnt.target.result)
                    }
                    setCargado(true)
                } else {
                    console.log('Carrito vacio:2');
                }
            }
        })
    }

    const getFormato = (valor) => {
        return Intl.NumberFormat('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(valor);
    }

    const borrarPedido = (pedido) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction(["contenidocarrito"], "readwrite").objectStore("contenidocarrito");
            let request = objStore.delete(pedido);

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }

            request.onsuccess = () => {
                getCarrito(true)
            }
        })
    }

    const quitarLibro = async (pedido_id, libro) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("contenidocarrito", "readwrite").objectStore("contenidocarrito");
            let request = objStore.get(pedido_id);

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }

            request.onsuccess = (evnt) => {
                let pedido = evnt.target.result
                pedido.info.detalles = pedido.info.detalles.filter(elemento => {
                    return elemento.data.id !== libro || elemento.data.obligatorio == 1
                })
                let resp = pedido.info.detalles.length > 0? objStore.put(pedido) : objStore.delete(pedido_id)
                resp.onerror = (evt) => {
                    console.error("Error de apertura", evt.target);
                }
                resp.onsuccess = () => {
                    getCarrito(true)
                }
            }
        })
    }

    const setNombre = async (value, pedido_id) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("contenidocarrito", "readwrite").objectStore("contenidocarrito");
            let request = objStore.get(pedido_id);

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }

            request.onsuccess = (evnt) => {
                let pedido = evnt.target.result
                pedido.info.nombre = value
                let resp = objStore.put(pedido)
                resp.onerror = (evt) => {
                    console.error("Error de guardado", evt.target);
                }
                resp.onsuccess = () => {
                    getCarrito(false)
                }
            }
        })
    }
    const setApPaterno = async (value, pedido_id) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("contenidocarrito", "readwrite").objectStore("contenidocarrito");
            let request = objStore.get(pedido_id);

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }

            request.onsuccess = (evnt) => {
                let pedido = evnt.target.result
                pedido.info.apellido_paterno = value
                let resp = objStore.put(pedido)
                resp.onerror = (evt) => {
                    console.error("Error de guardado", evt.target);
                }
                resp.onsuccess = () => {
                    getCarrito(false)
                }
            }
        })
    }
    const setApMaterno = async (value, pedido_id) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("contenidocarrito", "readwrite").objectStore("contenidocarrito");
            let request = objStore.get(pedido_id);

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }

            request.onsuccess = (evnt) => {
                let pedido = evnt.target.result
                pedido.info.apellido_materno = value
                let resp = objStore.put(pedido)
                resp.onerror = (evt) => {
                    console.error("Error de guardado", evt.target);
                }
                resp.onsuccess = () => {
                    getCarrito(false)
                }
            }
        })
    }

    const registroPago = async (subtotalVenta) => {
        if (inputsValidos) {
            setSubtotal(subtotalVenta)
            // console.log(subtotalVenta);
            setInicioPasarela(true)
        } else {
            setMensaje("LOS CAMPOS DE NOMBRE Y APELLIDO PATERNO SON REQUERIDOS");
        }
    }

    useEffect(() => getCarrito(false), [])

    const renderCarrito = () => {
        let elementos = <span className="text-4xl font-thin text-center uppercase text-gray-bk">Carrito vacio</span>
        if (inicioPasarela) {
            elementos = <Pasarela setVista={setVista} metodosPago={metodosPago} setInicioPasarela={setInicioPasarela} bookDB={bookDB} usuario={usuario} carritoBase={carritoBase} colegio={colegio} subtotalCarrito={subtotal} inventarioID={inventarioID} />
        } else if(lenghtCar > 0) {
            let subtotalVenta = 0
            elementos = (
                <div className="flex flex-col justify-between w-full p-4 lg:h-full">
                    <span className="block w-full px-4 py-3 text-sm font-rob-bold text-blue-button lg:text-base">RESUMEN DEL PEDIDO</span>
                    {carrito.map((data) => {
                        inputsValidos = inputsValidos && (
                            data.info.nombre.length > 1 &&
                            data.info.apellido_paterno.length > 1
                        )

                        return (
                            <div className="w-full p-4 rounded-md row-cebra" key={data.id}>
                                <div className="flex justify-end pb-3 font-rob-light text-red-notification">
                                    <span onClick={() => borrarPedido(data.id)} className="inline-block cursor-pointer">ELIMINAR TODO</span>
                                </div>
                                <div className="w-full py-4 border-t border-b border-gray-300 text-gray-bk">
                                    <div className="flex flex-col justify-between w-full lg:flex-row">
                                        <div className="w-full border-r border-gray-300 lg:w-1/3">
                                            <div className="relative flex flex-col w-full mt-6">
                                                <div className="relative flex w-full">
                                                    <div className="relative w-1/5">
                                                        <span className="mr-2 font-rob-medium text-basetext-left">COLEGIO:</span>
                                                    </div>
                                                    <div className="relative w-4/5">
                                                        <span className="text-base text-left font-rob-light">{data.info.colegio}</span>
                                                    </div>
                                                </div>
                                                <div className="relative flex w-full mt-6">
                                                    <div className="relative w-1/5">
                                                        <span className="inline mr-2 text-base text-left font-rob-medium">GRADO:</span>
                                                    </div>
                                                    <div className="relative w-4/5">
                                                        <span className="inline text-base text-left font-rob-light">{data.info.grado}</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="flex items-center w-full mt-6 mb-8 mr-8 bg-teal-lighter">
                                                <div className="flex flex-wrap justify-between mr-5">
                                                    <div className="w-full">
                                                        <label htmlFor={"nombre-" + data.id} className="text-base uppercase font-rob-medium text-blue-button">NOMBRE DEL ALUMNO: *</label>
                                                        <input defaultValue={data.info.nombre} onChange={evnt => setNombre(evnt.target.value, data.id)} type="text" name={"nombre-" + data.id} id={"nombre-" + data.id} key={"nombre-" + data.id} placeholder="ESCRIBIR NOMBRE" className="w-full p-2 py-2 placeholder-gray-300 bg-transparent border-b border-gray-300 outline-none font-rob-light" />
                                                    </div>
                                                    <div className="relative flex justify-between w-full">
                                                        <div className="w-1/2 pr-2 mt-6">
                                                            <label htmlFor={"paterno" + data.id} className="text-base uppercase font-rob-medium text-blue-button">Apellido Paterno: *</label>
                                                            <input defaultValue={data.info.apellido_paterno} onChange={evnt => setApPaterno(evnt.target.value, data.id)} type="text" name={"paterno" + data.id} id={"paterno" + data.id} key={"paterno" + data.id} placeholder="ESCRIBIR APELLIDO" className="w-full p-2 py-2 placeholder-gray-300 bg-transparent border-b border-gray-300 focus:outline-none font-rob-light" />
                                                        </div>
                                                        <div className="w-1/2 pl-2 mt-6">
                                                            <label htmlFor={"materno" + data.id} className="text-base uppercase font-rob-medium text-blue-button">Apellido Materno:</label>
                                                            <input defaultValue={data.info.apellido_materno} onChange={evnt => setApMaterno(evnt.target.value, data.id)} type="text" name={"materno" + data.id} id={"materno" + data.id} key={"materno" + data.id} placeholder="ESCRIBIR APELLIDO" className="w-full p-2 py-2 placeholder-gray-300 bg-transparent border-b border-gray-300 focus:outline-none font-rob-light" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="relative flex-col w-full px-5 lg:w-2/3">
                                            {data.info.detalles.map(detail => {
                                                let detalle = detail.data
                                                let cantidad = 1
                                                subtotalVenta += cantidad * detalle.precio
                                                subtotalCarrito += cantidad * detalle.precio

                                                return (
                                                    <div className="flex items-center justify-between w-full my-4" id={data.id+"-"+detalle.id} key={data.id+"-"+detalle.id}>
                                                        {/* <div className="relative self-start inline-block col-start-1 col-end-2 text-sm text-center border rounded-sm cursor-default min-w-min font-rob-regular border-gray-bk">
                                                            <span className="w-full h-full min-w-min font-rob-regular">{cantidad}</span>
                                                        </div> */}
                                                        <span className="w-1/2 text-base text-left font-rob-light">
                                                            {detalle.nombre}
                                                            <p className={"text-left uppercase font-rob-light text-red-notification" + (detalle.obligatorio == 1? "": " hidden")}> obligatorio </p>
                                                        </span>
                                                        <span className="text-base text-right font-rob-light">{detalle.isbn}</span>
                                                        <span className="text-base text-right font-rob-light text-green-button">${getFormato(detalle.precio)} MX</span>
                                                        <div className="col-span-1 font-rob-light">
                                                            <img onClick={() => quitarLibro(data.id, detalle.id)} src={bote} alt="" className={"relative float-right w-5 cursor-pointer" + (detalle.obligatorio == 1? " hidden": "")} />
                                                        </div>
                                                    </div>
                                                )
                                            })}
                                            <div className="relative flex items-center justify-end w-full">
                                                <span className="col-span-2 text-base text-right">SUBTOTAL &nbsp; &nbsp; &nbsp; </span>
                                                <span className="relative col-span-3 text-lg text-right text-green-button">${getFormato(subtotalVenta)} MX</span>
                                                <span className="col-span-2"></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                    <div className="flex flex-col items-center w-full px-4 py-4 md:px-16">
                        <div className="flex justify-end w-full max-w-7xl">
                            <span className="text-base text-gray-bk">
                                <span className="pr-8">SUBTOTAL</span>
                                <span className="relative text-lg text-right text-blue-button">${getFormato(subtotalCarrito)} MX</span>
                            </span>
                        </div>
                        <div className={"relative justify-center hidden w-full text-center uppercase lg:flex text-red-notification " + (inputsValidos? "" : "hidden")}>{mensaje}</div>
                        <div className="flex flex-col items-center w-full max-w-xs pt-20">
                            <div className="w-full">
                                <div onClick={() => registroPago(subtotalCarrito)} className={(inputsValidos? "bg-green-button cursor-pointer hover:bg-white hover:border-green-button hover:text-green-button" : "bg-gray-bk cursor-default") + " flex justify-center items-center appearance-none relative text-center h-14 my-1.5 rounded-md text-white border border-solid lg:text-lg md:text-base sm:text-sm"}>
                                    <span>SIGUIENTE</span>
                                </div>
                            </div>
                            <div className="w-full">
                                <div onClick={() => setVista('/vender')} className="flex justify-center appearance-none relative text-center h-14 my-1.5 items-center rounded-md text-gray-bk border border-solid border-gray-400 hover:bg-white hover:border-blue-button hover:text-blue-button lg:text-lg md:text-base sm:text-sm cursor-pointer">
                                    <span>SEGUIR VENDIENDO</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return elementos
    }

    return (
        <div className={"flex justify-center w-full min-h-contenedor overflow-x-hidden overflow-y-auto border-2 border-gray-300 rounded-md lg:h-auto " + (lenghtCar > 0? "items-start" : "items-center")}>
            { cargado? renderCarrito() : Loading() }
        </div>
    )
}

export default Carrito