import { useState, useEffect } from 'react'
import Vendidos from './Vendidos'
import Caja from './Caja'
import Vender from './Vender'
import Reporte from './Reporte'
import Carrito from './Carrito'
import Loading from './Loading'
import IndexedDB from "../BookDB/IndexedDB"
import Header from './Header'
import Footer from './Footer'

function Index({setLogued, urlHome, usuario}) {
    // let infocajaEspera      = false
    let infoColegioEspera   = false
    let inventarioEspera    = false
    let carrito_nuevo       = []

    const [ vista,          setVista        ] = useState('/')
    const [ carrito,        setCarrito      ] = useState([])
    const [ inventario,     setInventario   ] = useState([])
    const [ cajaAbierta,    setCajaAbierta  ] = useState(false)
    // const [ infocaja,       setinfocaja     ] = useState({})
    const [ colegio,        setColegio      ] = useState([])
    const [ ventas,         setVentas       ] = useState([])
    const [ reportes,       setReportes     ] = useState([])
    const [ modalCaja,      setModalCaja    ] = useState(false)
    const [ tituloModal,    setTituloModal  ] = useState("")
    const [ textoModal,     setTextoModal   ] = useState("")
    // const [ caja,           setCaja         ] = useState({
    //     "billete_1000": 0,
    //     "billete_500": 0,
    //     "billete_200": 0,
    //     "billete_100": 0,
    //     "billete_50": 0,
    //     "billete_20": 0,
    //     "conteo_monedas": 0,
    //     "monto_monedas": 0,
    //     "observaciones": ""
    // })

    const bookDB = new IndexedDB('BaseBooktrain', 9, [
        {
            name: 'infocaja',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "colegio",  type: { unique: false } },
                { name: "info",     type: { unique: false } },
            ]
        },
        {
            name: 'inventario',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "colegio",  type: { unique: false } },
                { name: "info",     type: { unique: false } },
            ]
        },
        {
            name: 'infocolegio',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "colegio",  type: { unique: false } },
                { name: "info",     type: { unique: false } },
            ]
        },
        {
            name: 'infocarrito',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "colegio",  type: { unique: false } },
                { name: "info",     type: { unique: false } },
            ]
        },
        {
            name: 'contenidocarrito',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "estatus",      type: { unique: false } },
                { name: "info",         type: { unique: false } },
                { name: "id_pedido",    type: { unique: false } },
            ]
        },
        {
            name: 'ventas',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "colegio",      type: { unique: false } },
                { name: "id_reporte",   type: { unique: false } },
                { name: "info",         type: { unique: false } },
                { name: "sync",         type: { unique: false } },
            ]
        },
        {
            name: 'cupones',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "codigo",   type: { unique: false } },
                { name: "info",     type: { unique: false } },
            ]
        },
        {
            name: 'reportes',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "colegio",          type: { unique: false } },
                { name: "nombre_colegio",   type: { unique: false } },
                { name: "status",           type: { unique: false } },
                { name: "codigo",           type: { unique: false } },
                { name: "efectivo",         type: { unique: false } },
            ]
        },
    ]);





    const setVistaGuardada = (ruta) => {
        if (ruta != '/') {
            localStorage.setItem('vista', ruta)
            setVista(ruta)
        }
    }

    const getVistaGuardada = () => {
        if (localStorage.getItem('vista') == undefined) {
            localStorage.setItem('vista', '/caja')
        }
        return localStorage.getItem('vista')
    }





    // const abrirModal = async (title, text) => {
    //     setModalCaja(true);
    //     setTituloModal(title);
    //     setTextoModal(text);
    // }





    const peticionesFetch = () => {
        Promise.all([
            // fetch((urlHome + '/api/venta_movil/caja'), {
            //     mode: 'cors',
            //     headers: {
            //         'Authorization':    'Bearer ' + usuario.token,
            //         'Content-Type':     'application/json',
            //         'Accept':           'application/json'
            //     }
            // }),
            fetch(urlHome+'/api/venta_movil/configuracion', {
                mode: 'cors',
                headers: {
                    'Authorization':    'Bearer ' + usuario.token,
                    'Content-Type':     'application/json',
                    'Accept':           'application/json'
                }
            }),
            fetch(urlHome+'/api/venta_movil/inventario', {
                mode: 'cors',
                headers: {
                    'Authorization':    'Bearer ' + usuario.token,
                    'Content-Type':     'application/json',
                    'Accept':           'application/json'
                }
            }),
            fetch(urlHome+'/api/venta_movil/cupones', {
                mode: 'cors',
                headers: {
                    'Authorization':    'Bearer ' + usuario.token,
                    'Content-Type':     'application/json',
                    'Accept':           'application/json'
                }
            }),
        ]).then(responses => {
            return Promise.all(responses.map(response => {
                if (response.ok) {
                    return response;
                } else {
                    return {success: false};
                }
            }));
        }).then(resps => {
            let i = 0;
            let consulta = bookDB;
            let estadoAvierto = true;
            resps.map(resp => {
                if (resp.ok) {
                    let url = resp.url;
                    resp.json().then((data) => {
                        i++;
                        switch (url) {
                            case urlHome + '/api/venta_movil/caja':
                                consulta.do(event => {
                                    let db = event.target.result;
                                    let transaction = db.transaction(['infocaja'], 'readwrite');
                                    let objetoCaja = transaction.objectStore("infocaja");
                                    
                                    objetoCaja.add({
                                        colegio: usuario.codigo_colegio,
                                        info: data.data
                                    })
                                    
                                    transaction.oncomplete = function() {
                                        // infocajaEspera = data.data;
                                        estadoAvierto = estadoAvierto && true;
                                        // setinfocaja(infocajaEspera);
                                        abrirModal('Caja abierta', 'La caja fue abierta exitosamente, ahora puede recibir operaciones de venta');
                                        setInformacionHooks();
                                        console.log('DB caja: ok');
                                    };

                                    transaction.onerror = function(event) {
                                        console.error(event.target.error.message, event.target);
                                        estadoAvierto = estadoAvierto && false;
                                        console.log('caja: ' + i);
                                    };
                                });
                                break;

                            case urlHome + '/api/venta_movil/configuracion':
                                consulta.do(evnt => {
                                    let db = evnt.target.result;
                                    let transaction = db.transaction(['infocolegio'], 'readwrite');
                                    let objetoCaja = transaction.objectStore("infocolegio");
                                    
                                    objetoCaja.add({
                                        colegio: usuario.codigo_colegio,
                                        info: data.data
                                    });
                                    
                                    transaction.oncomplete = function(event) {
                                        infoColegioEspera = data.data;
                                        estadoAvierto = estadoAvierto && true;
                                        setColegio(infoColegioEspera);
                                        setInformacionHooks();
                                        console.log('DB configuracion: ok');
                                    };

                                    transaction.onerror = function(event) {
                                        console.log(event.target.error);
                                        estadoAvierto = estadoAvierto && false;
                                        console.log('configuracion: ' + i);
                                    };
                                });
                                break;
    
                            case urlHome + '/api/venta_movil/inventario':
                                consulta.do(evnt => {
                                    let db = evnt.target.result;
                                    let transaction = db.transaction(['inventario'], 'readwrite');
                                    let objetoCaja = transaction.objectStore("inventario");

                                    objetoCaja.add({
                                        colegio: usuario.codigo_colegio,
                                        info: data.data
                                    });

                                    transaction.oncomplete = function(event) {
                                        inventarioEspera = data.data;
                                        estadoAvierto = estadoAvierto && true;
                                        setInventario(inventarioEspera);
                                        setInformacionHooks();
                                        console.log('DB inventario: ok');
                                    };

                                    transaction.onerror = function(event) {
                                        console.log(event.target.error);
                                        estadoAvierto = estadoAvierto && false;
                                        console.log('inventario: ' + i);
                                    };
                                });
                                break;

                            case urlHome + '/api/venta_movil/cupones':
                                consulta.do(evnt => {
                                    let db = evnt.target.result;
                                    let transaction = db.transaction(['cupones'], 'readwrite');
                                    let objetoCaja = transaction.objectStore("cupones");

                                    for(var i in data.data){
                                        objetoCaja.add({
                                            codigo: data.data[i].codigo,
                                            info: data.data[i]
                                        });
                                    }

                                    transaction.oncomplete = function(event) {
                                        console.log('DB cupones: ok');
                                    };

                                    transaction.onerror = function(event) {
                                        console.log(event.target.error);
                                        estadoAvierto = estadoAvierto && false;
                                        console.log('cupones: ' + i);
                                    };
                                });
                                break;

                            default:
                                console.log('Error [route]', i);
                                break;
                        }
                    });
                } else {
                    i++;
                    // console.log('Empty [' + i + ']');
                    // alert(56)
                    if (localStorage.getItem('vista') != '/caja' && '/reporte') {
                        setVistaGuardada(getVistaGuardada())
                    } else {
                        setVistaGuardada('/caja')
                    }
                }
            });
        }).catch((error) => {
            console.log('Hubo un problema con la petición:\n', error);
            setCajaAbierta(false);
            setVistaGuardada('/caja');
        });
    }






    const consultasDB = () => {
        let idCaja = usuario.codigo_colegio;
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("infocaja", "readonly").objectStore("infocaja").index("colegio");

            let request = objStore.get(idCaja);
            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    // infocajaEspera = evnt.target.result
                    // setinfocaja(infocajaEspera);
                    setInformacionHooks();
                } else {
                    if (window.navigator.onLine) {
                        peticionesFetch()
                    } else {
                        setCajaAbierta(false);
                        setVistaGuardada('/caja');
                    }
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("infocolegio", "readonly").objectStore("infocolegio").index("colegio");
            let request = objStore.get(idCaja);

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    infoColegioEspera = event.target.result.info;
                    // console.log('Colegio:', event.target.result.info.grados);
                    setColegio(infoColegioEspera);
                    setInformacionHooks();
                } else {
                    if (window.navigator.onLine) {
                        peticionesFetch()
                    } else {
                        setCajaAbierta(false);
                        setVistaGuardada('/caja');
                    }
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("inventario", "readonly").objectStore("inventario").index("colegio");
            let request = objStore.get(idCaja);

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (event) => {
                if (event.target.result) {
                    inventarioEspera = event.target.result;
                    setInventario(inventarioEspera.info);
                    setInformacionHooks();
                } else {
                    if (window.navigator.onLine) {
                        peticionesFetch()
                    } else {
                        setCajaAbierta(false);
                        setVistaGuardada('/caja');
                    }
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("contenidocarrito", "readonly").objectStore("contenidocarrito").index("estatus");

            let request = objStore.getAll('carrito');
            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    if (evnt.target.result.length > 0) {
                        carrito_nuevo.push(evnt.target.result)
                        setCarrito(evnt.target.result)
                    }
                } else {
                    console.log('Carrito vacio:1')
                }
            }
        });
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction("ventas", "readonly").objectStore("ventas").index("colegio");
            let request = objStore.getAll();

            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    // ventasSave = evnt.target.result
                    // console.log(ventasSave)
                    // console.log(evnt.target.result);
                    setVentas(evnt.target.result)
                    // syncVentas()
                } else {
                    console.log('Sin ventas:1');
                }
                // setCarga(true)
            }
        })
        /*            name: 'reportes',
            keyPath: { keyPath: "id", autoIncrement: true },
            indexes: [
                { name: "colegio",   type: { unique: false } },
                { name: "status",   type: { unique: false } },
                { name: "codigo",   type: { unique: false } },
                { name: "efectivo",   type: { unique: false } },
        */
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction(
                ["reportes"],
                "readonly"
            ).objectStore("reportes");
            let request = objStore.getAll();
            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    setReportes(evnt.target.result)
                    // console.log('reportes', reportes);
                } else {
                    console.log('Sin reportes');
                }
                // setCarga(true)
            }
        })
    }





    // const incertarCarrito = pedido => {
    //     let consulta = bookDB
    //     let data_pedido = {
    //         estatus: 'carrito',
    //         id_pedido: pedido.id,
    //         info: {
    //             id: pedido.id,
    //             nombre: (pedido.nombre != null? pedido.nombre : ""),
    //             apellido_paterno: (pedido.apellido_paterno != null? pedido.apellido_paterno : ""),
    //             apellido_materno: (pedido.apellido_materno != null? pedido.apellido_materno : ""),
    //             colegio: pedido.colegio,
    //             seccionEducativa: pedido.seccionEducativa,
    //             grado: pedido.grado,
    //             detalles: pedido.detalles.map(detalle => {
    //                 return {
    //                     state: true,
    //                     data: {
    //                         id: detalle.libro_id,
    //                         isbn: detalle.isbn,
    //                         nombre: detalle.libro,
    //                         obligatorio: infoColegioEspera.grados[0].niveles.filter(nivel => {
    //                             return nivel.nombre == pedido.grado
    //                         })[0].paquetes[0].libros.filter(libro => {
    //                             return libro.id == detalle.libro_id
    //                         })[0].obligatorio,
    //                         precio: detalle.precio
    //                     }
    //                 }
    //             }),
    //         }
    //     }

    //     consulta.do(event => {
    //         let db = event.target.result;
    //         let insertTransaction =  db.transaction(['contenidocarrito'], 'readwrite');
    //         let objectStore = insertTransaction.objectStore('contenidocarrito');

    //         objectStore.add(data_pedido)

    //         insertTransaction.oncomplete = async () => {
    //             carrito_nuevo.push(data_pedido)
    //             setCarrito(carrito_nuevo)
    //             console.log("Nuevo elemento agregado al carrito.")
    //         }
    //         insertTransaction.onerror = event = () => {}
    //     })
    // }





    // const sincronizarCarrito = async response => {
    //     let consulta = bookDB
    //     response.data.resumenes.map(pedido => {
    //         consulta.do(evnt => {
    //             let db = evnt.target.result;
    //             let objStore = db.transaction("contenidocarrito", "readonly").objectStore("contenidocarrito").index("id_pedido");
    //             let request = objStore.get(pedido.id);
                
    //             request.onerror = (event) => {
    //                 console.error("Error de apertura", event.target);
    //             }
    //             request.onsuccess = (evnt) => {
    //                 if (evnt.target.result) {
    //                     console.log('Existe');
    //                 } else {
    //                     console.log('No existe');
    //                     incertarCarrito(pedido)
    //                 }
    //             }
    //         })
    //     })
    // }





    // const syncDB = async () => {
    //     fetch((urlHome + '/api/venta_movil/carrito'), {
    //         mode: 'cors',
    //         headers: {
    //             'Authorization': 'Bearer ' + usuario.token,
    //             'Content-Type': 'application/json',
    //             'Accept': 'application/json'
    //         }
    //     })
    //     .then(response => {
    //         if (response.ok) {
    //             return response.json()
    //         } else {
    //             return {
    //                 success: false,
    //                 message: 'Carrito vacio'
    //             }
    //         }
    //     })
    //     .then(data => {
    //         if (data.success) {
    //             sincronizarCarrito(data)
    //         } else {
    //             console.log(data.message)
    //         }
    //     })
    //     .catch((error) => {
    //         console.log('Hubo un problema con la petición2:\n', error);
    //         console.error(error);
    //     });
    // }





    // Validación del usuario
    useEffect(async () => {
        if (window.navigator.onLine) {
            fetch((urlHome + '/api/user'), {
                mode: 'cors',
                headers: {
                    'Authorization': 'Bearer ' + usuario.token,
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                }
            })
            .then(response => {
                if (response.ok) {
                    return response.json()
                } else {
                    if (response.status == 401) {
                        console.log('Usuario: error')
                        localStorage.removeItem('usuario')
                        setLogued(false)
                    } else {
                        throw new Error(response)
                    }
                }
            })
            .then(data => {
                if (data.success) {
                    console.log('Usuario: ok')
                    consultasDB()
                } else {
                    console.log('Usuario: error')
                    consultasDB()
                }
            })
            .catch((error) => {
                console.log('Hubo un problema con la petición2:\n', error);
                console.error(error);
                consultasDB()
            });
            // if (window.navigator.onLine) {
            //     syncDB()
            //     setTimeout(syncDB, 200)
            // }
        } else {
            consultasDB()
        }
    }, [])






    const setInformacionHooks = () => {
        if (!cajaAbierta && infoColegioEspera && inventarioEspera) {
            setCajaAbierta(true);
            setVistaGuardada(getVistaGuardada());
            // if (window.navigator.onLine) {
            //     setTimeout(syncDB, 200)
            // }
        }
    }

    const recargaInfo = () => {
        setModalCaja(false)
    }





    // Renderizado de las paginas
    const Vista = ({ver}) => {
        let mostrar = <Loading />
        switch (ver) {
            case '/':
                mostrar =  <Loading />
                break;
            case '/caja':
                mostrar =  <Caja cajaAbierta={cajaAbierta} urlHome={urlHome} usuario={usuario} bookDB={bookDB} peticionesFetch={peticionesFetch} setVista={setVista} colegio={colegio} />
                break;
            case '/vendidos':
                mostrar =  <Vendidos bookDB={bookDB} colegio={colegio} />
                break;
            case '/vender':
                mostrar =  <Vender usuario={usuario} configuracion={colegio} bookDB={bookDB} setVista={setVistaGuardada} carritoBase={carrito} setCarritoBase={setCarrito} inventario={inventario} />
                break;
            case '/carrito':
                mostrar =  <Carrito setVista={setVistaGuardada} metodosPago={colegio.metodos_pago} carritoBase={setCarrito} bookDB={bookDB} usuario={usuario} colegio={colegio} />
                break;
            case '/reporte':
                mostrar =  <Reporte bookDB={bookDB} ventas={ventas} reportes={reportes} />
                break;
        }
        return mostrar;
    }

    return (
        <div className="relative w-full">
            <div className={(modalCaja? "flex" : "hidden") + " fixed justify-center items-center z-20 top-0 bottom-0 left-0 right-0"}>
                <div onClick={() => recargaInfo()} className="fixed top-0 bottom-0 left-0 right-0 z-0 bg-black opacity-40"></div>
                <div className="relative z-10 w-full h-auto max-w-lg max-h-full overflow-x-hidden overflow-y-auto">
                    <div className="w-full h-auto px-4 py-10">
                        <div className="w-full h-auto px-16 pt-8 pb-6 text-center text-gray-600 bg-white rounded-lg">
                            <div className="inline-flex items-center justify-center w-20 h-20 border-4 rounded-full border-green-circle">
                                <div className="w-6 h-10 mb-3 transform rotate-45 border-b-4 border-r-4 border-green-ok"></div>
                            </div>
                            <h1 className="pt-6 text-3xl font-rob-medium">{tituloModal}</h1>
                            <p className="pt-8 text-xl">{textoModal}</p>
                            <br />
                            <button onClick={() => recargaInfo()} className="px-4 py-3 text-white bg-blue-600 rounded-lg">OK</button>
                        </div>
                    </div>
                </div>
            </div>
            <Header setLogued={setLogued} vista={vista} setVista={setVistaGuardada} carrito={carrito.length} usuario={usuario} cajaAbierta={cajaAbierta} urlHome={urlHome}  bookDB={bookDB}  token={usuario.token} />

            <div className={(vista == '/'? "items-center" : "items-start") + " relative flex justify-center w-full px-4 py-4 min-h-contenedor lg:items-center md:min-h-base xl:px-16"}>
                <Vista ver={vista} />
            </div>

            <Footer />
        </div>
    )
}

export default Index