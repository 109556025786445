import { useEffect, useState } from "react"

export default ({setPasarela, metodosPago, datosContacto, bookDB, usuario, subtotalCarrito, saveRef, colegio, inventarioID}) => {
    const [ metodoPago,     setMetodoPago       ] = useState('no_id')
    const [ referenciaPago, setReferenciaPago   ] = useState('')
    const [ cupon,          setCupon            ] = useState('')
    const [ textoValidez,   setTextoValidez     ] = useState(<span></span>)
    const [ descuento,      setDescuento        ] = useState(0)
    const [ comisiones,     setComisiones       ] = useState(0)
    const [ calculoCom,     setCalculoCom       ] = useState(0)
    // let comisiones = 0;
    // let calculoCom = 0;
    // const [ checkTerminos,  setCheckTerminos    ] = useState(false)
    // console.log(metodosPago);
    // console.log("comision:"+metodosPago[2].formas_pago);

    const validacion = (
        metodoPago != 'no_id'
        // && checkTerminos
    )

    const getFormato = (valor) => {
        return Intl.NumberFormat('es-MX', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(valor);
    }

    const setVenta = (venta, id_reporte) => {
        let consulta = bookDB;
        consulta.do(event => {
            let db          = event.target.result;
            let transaction = db.transaction(['ventas'], 'readwrite');
            let objetoCaja  = transaction.objectStore("ventas");

            objetoCaja.add({
                colegio:    usuario.codigo_colegio,
                id_reporte: id_reporte,
                info:       venta,
                sync:       "false"
            })

            transaction.oncomplete = function(evnt) {
                saveRef(referenciaPago)
                setPasarela('/pago-realizado')
            };
            transaction.onerror = function(event) {
                console.error(event.target.error.message, event.target)
            };
        });
    }

    const actualizaInventario = async (libro) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db          = evnt.target.result;
            let objStore    = db.transaction(["inventario"], "readwrite").objectStore("inventario");
            let request     = objStore.get(inventarioID);

            request.onerror = (event) => {
                console.error("Error de borrado", event.target);
            }

            request.onsuccess = (evnt) => {
                let inventario = evnt.target.result
                inventario.info = inventario.info.map(stock => {
                    if (stock.id == parseInt(libro)) {
                        stock.existencias = parseInt(stock.existencias)-1
                    }
                    return stock
                })
                // console.log(inventario)

                let resp = objStore.put(inventario)

                resp.onerror = (evt) => {
                    console.error("Error de apertura", evt.target)
                }
                resp.onsuccess = () => {}
            }
        })
    }

    const marcarPedido = async (pedido_id) => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db          = evnt.target.result;
            let objStore    = db.transaction(["contenidocarrito"], "readwrite").objectStore("contenidocarrito");
            let request     = objStore.get(pedido_id);

            request.onerror = (event) => {
                console.error("Error de borrado", event.target);
            }

            request.onsuccess = (evnt) => {
                let pedido = evnt.target.result
                pedido.estatus = "venta"
                let resp = objStore.put(pedido)

                resp.onerror = (evt) => {
                    console.error("Error de apertura", evt.target)
                }
                resp.onsuccess = () => {
                    pedido.info.detalles.map(libro => {
                        actualizaInventario(libro.data.id).then(() => {
                            console.log('Inventario: ok');
                        })
                    })
                }
            }
        })
    }

    const fecha = new Date;

    const continuar = async () => {
        let consulta = bookDB;
        consulta.do(evnt => {
            let db = evnt.target.result;
            let objStore = db.transaction(["reportes"], "readonly").objectStore("reportes").index("status");
            let request = objStore.get('open');
            request.onerror = (event) => {
                console.error("Error de apertura", event.target);
            }
            request.onsuccess = (evnt) => {
                if (evnt.target.result) {
                    // console.log('Ya hay un reporte activo', evnt.target.result.codigo);
                    setOperacion(evnt.target.result.id)
                } else {
                    consulta.do(event => {
                        let db = event.target.result;
                        let transaction = db.transaction(['reportes'], 'readwrite');
                        let objetoCaja = transaction.objectStore("reportes");
                        let codigo_reporte = new Date().getTime();
                        objetoCaja.add({
                            colegio: usuario.codigo_colegio,
                            nombre_colegio: usuario.colegio,
                            status: 'open',
                            codigo: codigo_reporte,
                            efectivo: {
                                "billete_20":       0,
                                "billete_50":       0,
                                "billete_100":      0,
                                "billete_200":      0,
                                "billete_500":      0,
                                "billete_1000":     0,
                                "conteo_monedas":   0,
                                "monto_monedas":    0,
                                "observaciones":    ""
                            }
                        })
                        transaction.oncomplete = function() {
                            // console.log('Reporte: ok', evnt);
                            // setOperacion(evnt.target.result.id)
                            continuar()
                        };
                        transaction.onerror = function(event) {
                            console.error(event.target.error.message, event.target);
                        };
                    });
                }
            }
        })
    }

    const setOperacion = async (codigo_reporte) => {
        if (validacion) {
            let consulta = bookDB;
            consulta.do(evnt => {
                let db          = evnt.target.result;
                let objStore    = db.transaction("contenidocarrito", "readonly").objectStore("contenidocarrito").index("estatus");
                let request     = objStore.getAll('carrito');

                request.onerror = (event) => {
                    console.error("Error de apertura", event.target);
                }
                // console.log(Math.round(+new Date / 1e3 | 0));
                request.onsuccess = (evnt) => {
                    if (evnt.target.result) {
                        let datos_pedidos = {
                            "metodo_pago_id":       metodoPago,
                            "estatus":              'carrito',
                            "referencia":           referenciaPago,
                            "colegio":              colegio.nombre,
                            "nombre_contacto":      datosContacto.nombre_contacto,
                            "apellidos_contacto":   datosContacto.apellidos_contacto,
                            "correo_contacto":      datosContacto.email_contacto,
                            "email_licencias":      datosContacto.email_licencias,
                            "telefono_contacto":    datosContacto.tel_contacto,
                            "cupon_descuento":      descuento > 0? cupon : '',
                            "valor_descuento":      descuento,
                            "fecha_descuento":      new Date().getTime(),
                            "fecha_pago":           fecha.getDate() + '-' + (fecha.getMonth() < 9? '0' : '') + (fecha.getMonth() + 1) + '-' + fecha.getFullYear(),
                            "contenido":            evnt.target.result.map(data => {
                                marcarPedido(data.id)
                                let paquete_id  = 0
                                let grado_n     = data.info.grado
                                colegio.grados.map(grado => {
                                    grado.niveles.map(nivel => {
                                        let grado_nombre = nivel.nombre
                                        nivel.paquetes.map(paquete => {
                                            let id = paquete.id
                                            paquete.libros.map(lib => {
                                                data.info.detalles.map(libro => {
                                                    if(libro.data.id == lib.id && grado_nombre == grado_n){
                                                        paquete_id = id
                                                    }
                                                })
                                            })
                                        })
                                    })
                                })
                                return {
                                    "nombre_alumno":            data.info.nombre,
                                    "apellido_paterno_alumno":  data.info.apellido_paterno,
                                    "apellido_materno_alumno":  data.info.apellido_materno,
                                    "paquete_id":               paquete_id,
                                    "grado":                    grado_n,
                                    "libros": data.info.detalles.map(libro => {
                                        return {
                                            id:     libro.data.id,
                                            precio: libro.data.precio,
                                            nombre: libro.data.nombre,
                                            isbn:   libro.data.isbn,
                                        }
                                    })
                                }
                            })
                        }
                        setVenta(datos_pedidos, codigo_reporte)
                        // console.log(datos_pedidos)
                    } else {
                        console.log('Carrito vacio:1');
                    }
                }
            });
        }
    }

    function sha512(str) {
        return crypto.subtle.digest("SHA-512", new TextEncoder("utf-8").encode(str)).then(buf => {
            return Array.prototype.map.call(new Uint8Array(buf), x=>(('00'+x.toString(16)).slice(-2))).join('');
        });
    }

    const canjeo = () => {
        if (cupon.length > 0) {
            sha512(cupon).then(codigo => {
                // console.log(codigo)
                let consulta = bookDB
                consulta.do(evnt => {
                    let db          = evnt.target.result;
                    let objStore    = db.transaction(["cupones"], "readwrite").objectStore("cupones").index('codigo');
                    let request     = objStore.get(codigo);
        
                    request.onerror = (event) => {
                        console.error("Error de borrado", event.target);
                    }
        
                    request.onsuccess = (evnt) => {
                        if (evnt.target.result) {
                            let result = evnt.target.result
                            let fecha_hoy = new Date().getTime()
                            result.info.inicio+='000'
                            result.info.caducidad+='000'
                            result.info.inicio = parseInt(result.info.inicio)
                            result.info.caducidad = parseInt(result.info.caducidad)
                            let condition = (
                                result.info.inicio <= fecha_hoy &&
                                (fecha_hoy < result.info.caducidad || !Number.isInteger(result.info.caducidad))
                            )
    
                            if (condition) {
                                setDescuento((subtotalCarrito * result.info.descuento / 100).toFixed(2) * 1)
                                setTextoValidez(<div className="flex items-center justify-start text-xl text-green-700">&#x2714;<span className="pl-2 text-xs text-green-700">descuento disponible</span></div>)
                            } else {
                                setTextoValidez(<span className="text-xs text-red-notification">El cupón no es válido</span>)
                                setDescuento(0)
                                // console.log(result, codigo);
                                // console.log(result.info.inicio, fecha_hoy, fecha_hoy, result.info.caducidad);
                                // console.log(result.info.inicio + ' <= ' + fecha_hoy+' && '+fecha_hoy+' < '+result.info.caducidad);
                            }
                        } else {
                            setTextoValidez(<span className="text-xs text-red-notification">El cupón no es válido</span>)
                            setDescuento(0)
                        }
                    }
                })
            })
        } else {
            setTextoValidez(<span className="text-xs text-red-notification">No ha ingresado ningún cupón</span>)
            setDescuento(0)
        }
    }

    const cambioMetodo = (evnt) => { 
        let comision = 0;
        {metodosPago.map(metodo => {
            if (metodo.id == evnt.target.value) {
                metodo.formas_pago.map( forma => {
                    console.log(forma.comision)
                    if (forma.comision >= 0) {
                        comision = forma.comision
                    }   
                });
            }
        })}
        // let datos = evnt.target.value.split(',');
        // console.log("Id del metodo: "+datos[0]);
        // console.log("Pago: "+parseFloat(datos[1]));
        setMetodoPago(evnt.target.value)
        setComisiones(comision)
        setCalculoCom(parseFloat((comision / 100) * subtotalCarrito))
        console.log("Valor de la comision: "+comision);
        console.log("valor= "+(comision / 100) * subtotalCarrito)
    }
    const cambioReferencia  = (evnt) => { setReferenciaPago(evnt.target.value) }
    const cambioCupon       = (evnt) => {
        setCupon(evnt.target.value)
        setTextoValidez(<span></span>)
        setDescuento(0)
    }
    // const cambioCheck       = (evnt) => { setCheckTerminos(evnt.target.checked) }

    return (
        <div className="flex flex-col items-center w-full h-auto max-w-xl py-6 border border-gray-500 shadow rounded-xl">
            <div className="flex flex-wrap w-full px-3 mt-0 md:w-4/5 md:px-0 md:mt-10 place-content-between">
                <h2 className="uppercase font-rob-bold text-blue-button">pago</h2>
                <div>
                    <span className="font-rob-light text-gray-bk">2 - 2</span>
                </div>
            </div>
            <div className="relative flex justify-around w-full h-auto mt-12">
                <div className="relative flex flex-col w-full h-auto px-3 mb-6 md:w-4/5 md:px-0">
                    <div className="relative flex flex-col mb-4">
                        <label className="mb-2 text-left uppercase text-gray-bk font-rob-light">METODO DE PAGO *</label>
                        <select value={metodoPago} onChange={cambioMetodo} className="block w-full px-4 py-0 pr-8 text-gray-700 border rounded appearance-none bg-grey-lighter border-grey-lighter h-11">
                            <option value={'no_id'} disabled>Seleccione una opción</option>
                            {metodosPago.map(metodo => {
                                return <option value={metodo.id} key={metodo.id}>{metodo.descripcion}</option>
                            })}
                        </select>
                        <div className="absolute right-0 flex items-center px-2 text-gray-700 pointer-events-none top-12">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" className="w-4 h-4">
                                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"></path>
                            </svg>
                        </div>
                        <div className="flex flex-col my-4">
                            <label htmlFor="first_name" className="mb-2 text-left uppercase text-gray-bk font-rob-light">REFERENCIA</label>
                            <input value={referenciaPago} onChange={cambioReferencia} type="text" className="px-3 py-2 border rounded-md text-grey-darkest font-rob-light h-11" />
                        </div>
                        <div className="flex flex-col my-4">
                            <label htmlFor="first_name" className="mb-2 text-left uppercase text-gray-bk font-rob-light">CUPÓN DE DESCUENTO</label>
                            <div className="flex justify-between w-full">
                                <input value={cupon} onChange={cambioCupon} type="text" className="px-3 py-2 border rounded-md w-texto-cupon text-grey-darkest font-rob-light h-11" />
                                <button onClick={canjeo} className="relative flex items-center justify-center text-center border border-gray-400 border-solid rounded-md appearance-none cursor-pointer w-28 h-11 text-gray-bk hover:bg-white hover:border-blue-400 hover:text-blue-400 lg:text-lg md:text-base sm:text-sm">CANJEAR</button>
                            </div>
                        </div>
                        <div>{textoValidez}</div>
                    </div>
                    <br />
                    {/* <div className="flex items-center justify-end w-fu">
                        <label className="inline-flex items-center mt-3">
                            <input checked={checkTerminos} onChange={cambioCheck} type="checkbox" className="w-5 h-5 transition duration-100 ease-in-out border-2 border-gray-400 rounded shadow-md form-checkbox text-blue-button focus:border-blue-500 focus:ring-2 focus:ring-blue-300 focus:ring-opacity-50 focus:ring-offset-0 disabled:opacity-50 disabled:cursor-not-allowed" />
                            <span className="h-auto my-auto ml-2 text-gray-700 uppercase">
                                <small className="text-xs text-gray-bk font-rob-light">He leído y acepto las <a target="__blank" href="https://booktrain.com.mx/politicas" className="text-blue-button">políticas de compra </a> y <a target="__blank" href="https://booktrain.com.mx/aviso-de-privacidad" className="text-blue-button">aviso de privacidad</a></small>
                            </span>
                        </label>
                    </div> */}
                    <div className="flex flex-row mt-4">
                        <div className="box-border flex flex-col pr-3 text-right">
                            <span className="mb-3 text-gray-bk font-rob-bold">SUBTOTAL</span>
                            {/* <span className="mb-3 text-gray-bk font-rob-bold">COMISIÓN DE PAGO</span> */}
                            <span className="mb-3 text-gray-bk font-rob-bold">DESCUENTO</span>
                            <span className="mb-3 text-gray-bk font-rob-bold">COMISION</span>
                            <span className="mb-3 text-gray-bk font-rob-bold">TOTAL</span>
                        </div>
                        <div className="box-border flex flex-col items-start pl-3 text-right">
                            <span className="mb-3 text-green-button font-rob-regularBT">${subtotalCarrito} MXN</span>
                            {/* <span className="mb-3 text-green-button font-rob-regularBT">$0.00 MXN</span> */}
                            <span className="mb-3 text-green-button font-rob-regularBT">${getFormato(descuento)} MXN</span>
                            <span className="mb-3 text-green-button font-rob-regularBT">{comisiones}%</span>
                            <span className="mb-3 text-green-button font-rob-regularBT">${getFormato((subtotalCarrito + calculoCom) - descuento)} MXN</span>
                        </div>
                    </div>
                    <div className="relative flex flex-wrap content-end w-full h-auto mt-auto">
                        <div className="relative flex justify-center w-full">
                            <button onClick={continuar} className={"appearance-none relative block text-center py-1 px-3 w-1/2 my-1.5 rounded-md border border-solid text-white lg:text-lg md:text-base sm:text-sm " + (validacion? "border-gray-400 bg-green-button hover:border-green-button hover:text-green-button hover:bg-white cursor-pointer" : "opacity-50 cursor-default bg-gray-400 border-gray-400")}>
                                <span className="rob-bold">PAGAR</span>
                            </button>
                        </div>
                        <div className="relative flex justify-center w-full">
                            <div onClick={() => setPasarela('/datos-contacto')} className="appearance-none relative block text-center py-1 px-3 w-1/2 my-1.5 rounded-md text-gray-bk border border-solid border-gray-400 cursor-pointer hover:bg-white hover:border-blue-400 hover:text-blue-400 lg:text-lg md:text-base sm:text-sm">
                                <span className="rob-bold">REGRESAR</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
