import { useState } from 'react'
import DatosContacto from './DatosContacto'
import Pago from './Pago'
import PagoRealizado from './PagoRealizado'

export default ({setVista, metodosPago, setInicioPasarela, bookDB, usuario, carritoBase, colegio, subtotalCarrito, inventarioID}) => {
    // const [ pasarela,       setPasarela         ] = useState('/pago')
    const [ pasarela,       setPasarela         ] = useState('/datos-contacto')
    const [ referencia,     setReferencia       ] = useState('')
    const [ datosContacto,  setDatosContacto    ] = useState({
        nombre_contacto: '',
        apellidos_contacto: '',
        tel_contacto: '',
        email_contacto: ''
    })

    let mostrar = <DatosContacto setPasarela={setPasarela} setInicioPasarela={setInicioPasarela} />

    switch (pasarela) {
        case '/datos-contacto':
            mostrar = <DatosContacto setPasarela={setPasarela} setInicioPasarela={setInicioPasarela} datosContacto={datosContacto} setDatosContacto={setDatosContacto} />
            break;

        case '/pago':
            mostrar = <Pago setPasarela={setPasarela} metodosPago={metodosPago} datosContacto={datosContacto} bookDB={bookDB} usuario={usuario} subtotalCarrito={subtotalCarrito} saveRef={setReferencia} colegio={colegio} inventarioID={inventarioID} />
            break;

        case '/pago-realizado':
            mostrar = <PagoRealizado setVista={setVista} carritoBase={carritoBase} referencia={referencia} />
            break;

        default:
            mostrar = <DatosContacto setPasarela={setPasarela} setInicioPasarela={setInicioPasarela} />
            break;
    }

    return (
        <div className="flex items-start justify-center w-full p-4 lg:min-h-full">
            {mostrar}
        </div>
    )
}
