import { useState } from "react"
import logo_principal from "../../assets/img/logo_principal.svg"

function Login({setLogued, urlHome, setUsuario}) {
    const [ mail,       setMail     ] = useState('')
    const [ pass,       setPass     ] = useState('')
    const [ mensaje,    setMensaje  ] = useState('')

    const inputMail = (evnt) => {
        setMail(evnt.target.value)
    }

    const inputPass = (evnt) => {
        setPass(evnt.target.value)
    }

    const enterPress = (evnt) => {
        if (evnt.code == 'Enter' || evnt.charCode == 13) {
            loggearse()
        }
    }

    const loggearse = async () => {
        if(mail.length > 0 && pass.length > 0 && window.navigator.onLine) {
            let credenciales = {
                email: mail,
                password: pass
            };

            fetch((urlHome + '/api/login'), {
                method: 'POST',
                body: JSON.stringify(credenciales),
                headers: {
                    'Content-Type': 'application/json'
                }
            })
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    localStorage.setItem('usuario', JSON.stringify(data.data))
                    setUsuario(data.data)
                } else {
                    setMensaje(data.message)
                }
                setLogued(data.success)
            }).catch(function(error) {
                console.log('Hubo un problema con la petición:\n', error);
                setMensaje('Lo sentimos ocurrio un error, intentelo más tarde')
            });
        } else {
            if (!window.navigator.onLine) {
                setMensaje('Es necesario conectarse a internet para iniciar sesión')
            } else if (mail.length == 0) {
                setMensaje('Falta el correo electrónico')
            } else if (pass.length == 0) {
                setMensaje('Falta la contraseña')
            } else {
                setMensaje('Error: falta el usuario o la contraseña')
            }
        }
    }

    return (
        <div className="flex flex-col justify-between w-full min-h-screen overflow-x-hidden overflow-y-auto md:flex-row">

            <div className="box-border w-full h-auto px-10 md:px-20 md:max-w-xl md:h-full">

                <div className="box-border flex flex-col justify-between w-full h-full pt-10 pb-8 md:h-screen 2xl:pt-20">

                    <div className="flex justify-center">
                        <img className="w-64 h-20 logo-bk" src={logo_principal} alt="Logo principal" />
                    </div>

                    <div className="w-full h-auto">
                        <div className="w-full" method="POST">

                            <div className="mt-7">
                                <input value={mail} onInput={inputMail} onKeyPress={enterPress} type="email" className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none h-14 focus:outline-none focus:z-10 lg:text-lg sm:text-sm md:text-base" placeholder="Correo electrónico" name="email" />
                            </div>

                            <div className="mt-7">
                                <input value={pass} onInput={inputPass} onKeyPress={enterPress} type="password" className="relative block w-full px-3 py-2 text-gray-900 placeholder-gray-500 border border-gray-300 rounded-md appearance-none h-14 focus:outline-none focus:z-10 lg:text-lg sm:text-sm md:text-base" placeholder="Contraseña" name="password" />
                            </div>

                            <div className={"w-full text-sm text-left text-red-700 font-rob-regular " + (mensaje.length > 0? "pt-3 mt-5" : "")}>
                                <span>{mensaje}</span>
                            </div>

                            <button onClick={() => loggearse()} className="relative flex items-center justify-center w-full px-3 py-3 my-5 text-center text-white uppercase border border-solid rounded-md appearance-none mt-7 bg-blue-button h-14 hover:bg-white hover:border-blue-button hover:text-blue-button lg:text-lg md:text-base sm:text-sm rob-bold">
                                Entrar
                            </button>

                        </div>

                        <div className="box-border flex flex-col w-full px-6 py-4 text-center">
                            <a href="https://booktrain.com.mx/recuperar" target="_blank" className="cursor-pointer text-blue-button rob-light">¿Has olvidado contraseña?</a>
                            <div className="w-full mt-2 border-b border-gray-400"></div>
                        </div>

                        <a href="https://booktrain.com.mx/registro" target="_blank">
                            <div className="relative flex items-center justify-center w-full px-3 py-3 my-5 text-center text-white uppercase border border-solid rounded-md appearance-none bg-green-button h-14 hover:bg-white hover:border-green-button hover:text-green-button lg:text-lg md:text-base sm:text-sm rob-bold">
                                <span>Registrarse</span>
                            </div>
                        </a>

                    </div>
                    <div>
                        <a href="https://www.youtube.com/watch?v=OqZEtwu30Cw" target="blank">

                            <div className="relative flex items-center justify-center w-full px-3 py-3 my-5 text-center text-white uppercase border border-solid rounded-md appearance-none boton_guia_compra h-14 lg:text-lg bg-red-book hover:bg-white hover:text-red-book hover:border-red-book md:text-base sm:text-sm rob-bold">
                                <span>Guía de compra</span>
                            </div>

                        </a>
                    </div>

                    <div className="w-full border-gray-400 md:border-b"></div>
                </div>
            </div>

            <div className="box-border relative flex items-end w-full px-4 bg-right bg-no-repeat bg-cover md:h-screen md:w-custom-bg md:bg-fondo-bk md:overflow-hidden">
                <div className="relative bottom-0 flex flex-col w-full md:w-11/12 flex-column md:absolute">
                    <div className="hidden max-w-full md:block md:w-max">
                        <h1 className="text-white font-rob-bold lg:text-lg md:text-base">BIENVENIDO A LA TIENDA ONLINE PARA COLEGIOS</h1>
                        <span className="text-white rob-light sm:text-sm md:text-base">Ingresa o regístrate para adquirir tu paquete de libros</span>
                    </div>

                    <div className="md:mt-5">
                        <div className="w-full border-b border-gray-400"></div>
                        <div className="flex flex-col justify-around w-full py-1 text-center md:flex-row h-28 md:h-8 md:py-0 opacity-80">
                            <span className="text-xs text-gray-bk md:text-white"><a className="cursor-pointer" href="https://booktrain.com.mx/aviso-de-privacidad/" target="_blank">Aviso de privacidad</a> / <a className="cursor-pointer" href="https://booktrain.com.mx/politicas/" target="_blank">Políticas de compra, entrega y devolución</a></span>
                            <span className="text-xs text-gray-bk md:text-white">© 2021 MYTANG S.A de C.V</span>
                            <span className="text-xs text-gray-bk md:text-white">Desarrollado por <b><a className="cursor-pointer" href="http://inkwonders.com" target="_blank">INK WONDERS</a></b></span>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login